import { createAsyncThunk } from '@reduxjs/toolkit'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { fetchCommonClients, selectCommonUsers } from '../../../../entities/Common'
import { fetchClients } from '../../../../pages/ClientsPage'
import { selectNewClient } from '../selectors/selectNewClient'
import { INewClientDTO } from '../types/NewClient'

export const addNewClient = createAsyncThunk<void, void, ThunkConfig<string>>(
	'newClient/addNewClient',
	async (_, thunkAPI) => {
		const {
			dispatch,
			getState,
			extra: { api },
			rejectWithValue
		} = thunkAPI

		const newClient = selectNewClient(getState())
		const commonUsers = selectCommonUsers(getState())

		const managers = newClient.managerNames.reduce(
			(acc: { id: string }[], managerName) => {
				const user = commonUsers.find((user) => {
					return user.fullName === managerName
				})
				if (user) acc.push({ id: user.userId })
				return acc
			},
			[]
		)

		if (!managers.length) {
			showToast({
				type: 'error',
				toastId: '#extra',
				message: 'Mangers chosen are not valid'
			})
			return
		}

		const companyNames = newClient.companyNames?.map((companyName) =>
			companyName?.trim()
		)

		const newClientDto: INewClientDTO = {
			companyNames,
			managers
		}

		if (newClient.tfbOffice) newClientDto.tfbOffice = newClient.tfbOffice

		try {
			const { data: { objectId } } = await api.post('/clients', newClientDto)

			showToast({
				type: 'success',
				toastId: 'newClientCreated',
				message: 'New client created'
			})

			dispatch(fetchClients())
			dispatch(fetchCommonClients())

			return objectId?.length ? objectId : ''
		} catch (e) {
			console.log(e)
			appHandleError(e, 'newClientCreated', 'Failed to add a new client')
			return rejectWithValue('Error occurred while creating a new client')
		}
	}
)
