import { StoreSchema } from '../../../../shared/config/storeConfig/StoreSchema'

export const selectDataToUpdate = (state: StoreSchema) => {
	const login = state.user.userData.login
	const email = state.user.userData.email
	const phone = state.user.userData.phone
	const tfbOffice = state.user.userData.tfbOffice
	const rights = state.user.userData.rights
	const fullName = state.user.userData.fullName
	const position = state.user.userData.position
	const departmentName = state.user.userData.departmentName

	return {
		login,
		email,
		phone,
		tfbOffice,
		rights,
		fullName,
		position,
		departmentName
	}
}
