import axios from 'axios'

// const baseURL = 'http://localhost:3007'
const baseURL = '/api'
// const baseURL = 'https://10.40.0.2:7061'

export const $API = axios.create({
    baseURL,
    withCredentials: true
})
