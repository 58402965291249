import { useState } from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { SelectChangeEvent } from '@mui/material/Select'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SelectOptions } from '../../../shared/ui/MuiSelect/MuiSelectValidate'
import { MuiSelect } from '../../../shared/ui/MuiSelect/MuiSelect'

const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: '1rem'
}

interface FunctionalitySelectProps {
	value: string
	label: string
	labelId: string
	selectOptions: SelectOptions
	onSelect: (e: SelectChangeEvent) => void
	disableFunctionalityPermission: (funcId: string) => void
	enableFunctionalityPermission: (funcId: string) => void
	checkboxVale?: boolean
	className?: string
}

export const FunctionalitySelect = (props: FunctionalitySelectProps) => {
    const {
        value,
        label,
        labelId, // functionalityTemplateId is used as labelId and name
        onSelect,
        className,
        selectOptions,
        checkboxVale = false,
        disableFunctionalityPermission,
        enableFunctionalityPermission
    } = props

    const [checked, setChecked] = useState(checkboxVale)

    const handleFunctionalitySelect = () => {
        setChecked(prev => {
            if (prev) {
                disableFunctionalityPermission(labelId)
            }
            if (!prev) {
                enableFunctionalityPermission(labelId)
            }
            return !prev
        })
    }

    return (
        <Box
            className={className}
            sx={style}
        >
            <FormControlLabel
                label={label}
                control={
                    <Checkbox
                        onClick={handleFunctionalitySelect}
                        checked={checked}
                    />
                }
            />
            <MuiSelect
                styles={{ width: '18.75rem', minWidth: '18.75rem' }}
                handleSelectChange={onSelect}
                selectOptions={selectOptions}
                disabled={!checked}
                labelId={labelId}
                name={labelId}
                value={value}
                label={label}
                size="medium"
            />
        </Box>
    )
}
