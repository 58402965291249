import { createAsyncThunk } from '@reduxjs/toolkit'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { fetchLicenses } from '../../../../pages/LicensesPage'
import { selectNewLicense } from '../selectors/selectNewLicense'

export const addNewLicense = createAsyncThunk<string, void, ThunkConfig<string>>(
    'newLicense/addNewLicense',
    async (_, thunkAPI) => {
        const {
            dispatch,
            getState,
            extra: { api },
            rejectWithValue
        } = thunkAPI

        const newLicense = selectNewLicense(getState())
        const expirationDate = newLicense.expirationDate?.toISOString()
        const versionsArr = newLicense.allowedVersions.length
            ? newLicense.allowedVersions.split(',')
            : []

        const licenseDTO = {
            ...newLicense,
            allowedVersions: versionsArr,
            expirationDate
        }

        try {
            const { data: { objectId } } = await api.post('/licenses', licenseDTO)

            showToast({
                type: 'success',
                toastId: 'newLicenseCreated',
                message: 'New license created'
            })

            dispatch(fetchLicenses())

            return objectId?.length ? objectId : ''
        } catch (e: any) {
            console.log(e)
            appHandleError(e, 'newLicenseCreated', 'Failed to add a new license')
            return rejectWithValue('Error occurred while creating a new license')
        }
    }
)
