import { createAsyncThunk } from '@reduxjs/toolkit'
import { PaginatedData } from '../../../../shared/types/PaginatedData'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { addQueryParams } from '../../../../shared/lib/addQueryParams/addQueryParams'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { ILicense } from '../../../../entities/License'
import { selectLimit } from '../selectors/selectLimit'
import { selectPage } from '../selectors/selectPage'
import { selectSortValues } from '../selectors/selectSortValues'
import { selectQueryFilter } from '../selectors/selectQueryFilter'
import { selectTypeFilter } from '../selectors/selectTypeFilter'

export const fetchLicenses = createAsyncThunk<
	PaginatedData<ILicense>,
	void,
	ThunkConfig<string>
>('licenses/fetchLicenses', async (_, thunkAPI) => {

    const { extra: { api }, rejectWithValue, getState } = thunkAPI

    const page = selectPage(getState())
    const limit = selectLimit(getState())
    const type = selectTypeFilter(getState())
    const sortValues = selectSortValues(getState())
    const queryFilters = selectQueryFilter(getState())

    const params = { ...queryFilters, ...sortValues, page, limit, type }

    addQueryParams(params)
    try {
        const response = await api.get<PaginatedData<ILicense>>(
            '/licenses',
            { params }
        )

        return response.data
    } catch (e) {
        console.log(e)
        appHandleError(e, 'fetchLicenses', 'Failed to fetch "licenses" data')
        return rejectWithValue('Error occurred on fetching licenses list')
    }
})
