import mainLogo from '../../assets/svgs/mainLogo.svg'

interface AppLogoProps {
	height?: string
	width?: string
	className?: string
}

export const AppLogo = (props: AppLogoProps) => {
    const { height = '35px', width = '224px', className } = props

    return (
        <img
            className={className}
            src={mainLogo}
            alt="t4b logo"
            width={width}
            height={height}
        />
    )
}
