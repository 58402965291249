import React, { ChangeEvent, ReactNode, useMemo } from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { RemoveButton } from '../../../shared/ui/RemoveButton/RemoveButton'

const style = { width: '24rem', margin: '0' }

interface FunctionalityValueProps {
	value: string
	valueId: string
	funcIndex: number
	register: UseFormRegister<any>
	validationErrors: FieldErrors<any>
	onRemoveFuncValue: (funcIndex: number, valueId: string) => void
	onFunctionalityValueChange: (value: string, funcIndex: number, valueId: string) => void
	functionalityValuesLength: number
	className?: string;
	styles?: SxProps
}

export const FunctionalityValue = (props: FunctionalityValueProps) => {
    const {
        styles,
        className,
        funcIndex,
        valueId,
        value = '',
        register,
        validationErrors: errors,
        onRemoveFuncValue,
        onFunctionalityValueChange,
        functionalityValuesLength
    } = props

    const sxStyle = useMemo(() => ({ ...style, ...styles }), [styles])

    const handleRemove = () => {
        onRemoveFuncValue(funcIndex, valueId)
    }

    const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        onFunctionalityValueChange(e.target.value, funcIndex, valueId)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <TextField
                required
                type="text"
                sx={sxStyle}
                label="Value"
                variant="outlined"
                className={className}
                error={!!errors?.[`value${valueId}`]?.message}
                helperText={(errors?.[`value${valueId}`]?.message as ReactNode) || ''}
                {...register(`value${valueId}`, {
                    required: 'Fill out this field, please'
                })}
                onChange={handleValueChange}
                value={value}
            />
            <RemoveButton
                tooltipPlacement="left"
                onRemove={handleRemove}
                tooltipTitle="Delete value"
                disabled={functionalityValuesLength === 1}
            />
        </Box>
    )
}
