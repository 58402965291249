export enum Role {
	CUSTOM = 'custom',
	ADMIN = 'admin',
	ANALYST = 'analyst',
	DEVELOPER = 'developer',
	INTERN = 'intern',
	QA = 'qa',
	SALES = 'sales',
	SUCCESS = 'success',
	SUPPORT = 'support',
	TECH_WRITER = 'techWriter',
}

export interface RoleSchema {
	role: Role
}
