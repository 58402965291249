import React, { ChangeEvent } from 'react'
import TextField from '@mui/material/TextField'
import { useController, UseControllerProps } from 'react-hook-form'

interface ProductNamesAutocompleteProps extends UseControllerProps {
	onProductNameChange: (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void
}

export const ProductNamesAutocomplete = (props: ProductNamesAutocompleteProps) => {
    const { onProductNameChange } = props
    const { field, fieldState } = useController(props)

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        field.onChange(e)
        onProductNameChange(e)
    }

    return (
        <TextField
            {...field}
            required
            fullWidth
            type="text"
            label="Product name"
            variant="outlined"
            error={!!fieldState.error?.message}
            helperText={(fieldState.error?.message as React.ReactNode) || ''}
            onChange={handleChange}
        />
    )
}
