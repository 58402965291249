import { INewClient, NewClientSchema } from '../types/NewClient'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addNewClient } from '../services/addNewClient'

const newClient: INewClient = {
	companyNames: [''],
	managerNames: [],
	tfbOffice: ''
}

const initialState: NewClientSchema = {
	isLoading: false,
	error: '',
	newClient
}

const newClientSlice = createSlice({
	name: 'newClient',
	initialState,
	reducers: {
		resetNewClient: (state) => {
			state.newClient = newClient
		},
		addNewCompanyName: (state) => {
			state.newClient.companyNames.push('')
		},
		removeCompanyName: (state, action: PayloadAction<number>) => {
			state.newClient.companyNames.splice(action.payload, 1)
		},
		setCompanyName: (state, action: PayloadAction<{
			companyName: string,
			index: number
		}>) => {
			const { companyName, index } = action.payload
			state.newClient.companyNames[index] = companyName
		},
		setManagerNames: (state, action) => {
			state.newClient.managerNames = action.payload
		},
		setTfbOffice: (state, action: PayloadAction<string>) => {
			state.newClient.tfbOffice = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addNewClient.pending, (state) => {
				state.isLoading = true
				state.error = ''
			})
			.addCase(addNewClient.fulfilled, (state) => {
				state.isLoading = false
			})
			.addCase(addNewClient.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})
	}
})

export const { actions: newClientActions } = newClientSlice
export const { reducer: newClientReducer } = newClientSlice
