import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { SimilarNames, SimilarNamesMapped } from '../types/SimilarNames'
import { mapSimilarNames } from '../lib/mappers/mapSimilarNames'
import { addNewClient } from '../../../AddNewClient/model/services/addNewClient'
import { modalActions } from '../../../../entities/Modal'
import { newClientActions } from '../../../AddNewClient'

export const fetchSimilarNames = createAsyncThunk<
	SimilarNamesMapped | string,
	string[],
	ThunkConfig<string>
>(
    'fetchSimilarNames',
    async (companyNames, thunkAPI) => {

        const { extra: { api }, dispatch, rejectWithValue } = thunkAPI
        const query = companyNames.reduce((acc, name) => `${acc}companyName=${name}&`, '')
        try {
            const res = await api.get<SimilarNames>(`/clients/similar-names?${query}`)

            if (res.status === 204) {
                const result = await dispatch(addNewClient())

                if (result.meta.requestStatus === 'fulfilled') {
                    const clientId = result.payload

                    dispatch(modalActions.hideModal())
                    dispatch(newClientActions.resetNewClient())
                    return `/clients/${clientId}`
                }
                return '/clients'
            }

            return mapSimilarNames(res.data)
        } catch (e) {
            console.log(e)
            appHandleError(e, 'fetchSimilarNames', 'Similar company names search has failed')
            return rejectWithValue('Error occurred on similar company names')
        }
    })
