import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ILicenseWithUpdates, LicenseSchema, LicenseType } from '../types/License'
import { fetchLicense } from '../services/fetchLicense'

const licenseInitValues: ILicenseWithUpdates = {
    licenseId: '',
    creationDate: '',
    type: LicenseType._,
    // @ts-ignore: init license state as an empty string
    state: '',
    maxLiveInstances: 0,
    allowedVersions: [],
    numberOfInstances: '',
    clientBrief: {
        clientId: '',
        companyNames: []
    },
    productBrief: {
        productId: '',
        name: '',
        functionalitiesTemplates: []
    },
    licenseUpdates: []
}

const initialState: LicenseSchema = {
    licenseEditable: licenseInitValues,
    cachedLicense: licenseInitValues,
    isLoading: false,
    error: ''
}

const licenseSlice = createSlice({
    name: 'license',
    initialState,
    reducers: {
        resetLicenseEditable: (state) => {
            state.licenseEditable = state.cachedLicense
        },
        setExpirationDate: (state, action) => {
            state.licenseEditable.expirationDate = action.payload
        },
        setType: (state, action) => {
            state.licenseEditable.type = action.payload
        },
        setState: (state, action) => {
            state.licenseEditable.state = action.payload
        },
        setMaxLiveInstances: (state, action) => {
            state.licenseEditable.maxLiveInstances = action.payload
        },
        setAllowedVersions: (state, action) => {
            state.licenseEditable.allowedVersions = action.payload
        },
        disableFunctionality: (
            state,
            action: PayloadAction<string>
        ) => {
            const { payload: functionalityId } = action
            delete state.licenseEditable.functionalitiesSet![functionalityId]
        },
        enableFunctionality: (
            state,
            action: PayloadAction<string>
        ) => {
            const { payload: functionalityId } = action
            if (!state.licenseEditable.functionalitiesSet) {
                state.licenseEditable.functionalitiesSet = {}
				state.licenseEditable.functionalitiesSet![functionalityId] = ''
            } else {
				state.licenseEditable.functionalitiesSet![functionalityId] = ''
            }
        },
        updateFunctionalitiesSet: (
            state,
            action: PayloadAction<{ functionalityId: string, value: string }>
        ) => {
            const { functionalityId, value } = action.payload
			state.licenseEditable.functionalitiesSet![functionalityId] = value
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLicense.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(fetchLicense.fulfilled, (state, action) => {
                state.isLoading = false
                state.licenseEditable = action.payload
                state.cachedLicense = action.payload
            })
            .addCase(fetchLicense.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { actions: licenseActions } = licenseSlice
export const { reducer: licenseReducer } = licenseSlice
