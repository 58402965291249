import { createAsyncThunk } from '@reduxjs/toolkit'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import { fetchProfileUser } from '../../../../entities/User'
import { selectDataToUpdate } from '../selectors/selectDataToUpdate'
import { fetchCommonUsers } from '../../../../entities/Common'
import { mapC_Suit } from '../../../../shared/lib/mappers/mapC_Suit'

export const updateProfile = createAsyncThunk<void, void, ThunkConfig<string>>(
    'updateProfile',
    async (_, thunkAPI) => {

        const { dispatch, extra: { api }, rejectWithValue, getState } = thunkAPI
        const profileDataToUpdate = selectDataToUpdate(getState())
        const departmentName = mapC_Suit(profileDataToUpdate.departmentName)
        const profileDTO = { ...profileDataToUpdate, departmentName }
        try {
            await api.put('/users/own', profileDTO)

            showToast({
                type: 'success',
                toastId: 'updateProfile',
                message: 'Success'
            })

            dispatch(fetchProfileUser())
            dispatch(fetchCommonUsers())
        } catch (e) {
            console.log(e)
            appHandleError(e, 'updateProfile', 'Profile update failed')
            return rejectWithValue('Error occurred on profile data update')
        }
    }
)
