import { RouteProps } from 'react-router-dom'
import { LoginPage } from '../../../pages/LoginPage'
import { UsersPage } from '../../../pages/UsersPage'
import { ProfilePage } from '../../../pages/ProfilePage'
import { ClientsPage } from '../../../pages/ClientsPage'
import { LicensePage } from '../../../pages/LicensePage'
import { LicensesPage } from '../../../pages/LicensesPage'
import { ProductsPage } from '../../../pages/ProductsPage'
import { NotFoundPage } from '../../../pages/NotFoundPage/ui/NotFoundPage'
import { ProductPage } from '../../../pages/ProductPage'
import { ClientPage } from '../../../pages/ClientPage'

export type AppRoutesProps = RouteProps & {
	private?: boolean
}

export enum AppRoutes {
	NOT_FOUND = 'not_found',
	LOGIN = 'login',
	USERS = 'users',
	USERS_USERID = 'users_userId',
	PROFILE = 'profile',
	CLIENTS = 'clients',
	CLIENTS_CLIENTID = 'clients_clientId',
	LICENSES = 'licenses',
	LICENSES_LICENSEID = 'licenses_licenseId',
	PRODUCTS = 'products',
	PRODUCTS_PRODUCTID = 'products_productId',
}

export const RoutePath: Record<AppRoutes, string> = {
    [AppRoutes.NOT_FOUND]: '*',
    [AppRoutes.LOGIN]: '/login',
    [AppRoutes.USERS]: '/users',
    [AppRoutes.USERS_USERID]: '/users/:userId',
    [AppRoutes.PROFILE]: '/profile',
    [AppRoutes.CLIENTS]: '/clients',
    [AppRoutes.CLIENTS_CLIENTID]: '/clients/:clientId',
    [AppRoutes.LICENSES]: '/licenses',
    [AppRoutes.LICENSES_LICENSEID]: '/licenses/:licenseId',
    [AppRoutes.PRODUCTS]: '/products',
    [AppRoutes.PRODUCTS_PRODUCTID]: '/products/:productId'
}

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
    [AppRoutes.NOT_FOUND]: {
        path: RoutePath.not_found,
        element: <NotFoundPage />,
        private: true
    },
    [AppRoutes.LOGIN]: {
        path: RoutePath.login,
        element: <LoginPage />
    },
    [AppRoutes.PROFILE]: {
        path: RoutePath.profile,
        element: <ProfilePage />,
        private: true
    },
    [AppRoutes.LICENSES]: {
        path: RoutePath.licenses,
        element: <LicensesPage />,
        private: true
    },
    [AppRoutes.LICENSES_LICENSEID]: {
        path: RoutePath.licenses_licenseId,
        element: <LicensePage />,
        private: true
    },
    [AppRoutes.CLIENTS]: {
        path: RoutePath.clients,
        element: <ClientsPage />,
        private: true
    },
    [AppRoutes.CLIENTS_CLIENTID]: {
        path: RoutePath.clients_clientId,
        element: <ClientPage />,
        private: true
    },
    [AppRoutes.PRODUCTS]: {
        path: RoutePath.products,
        element: <ProductsPage />,
        private: true
    },
    [AppRoutes.PRODUCTS_PRODUCTID]: {
        path: RoutePath.products_productId,
        element: <ProductPage />,
        private: true
    },
    [AppRoutes.USERS]: {
        path: RoutePath.users,
        element: <UsersPage />,
        private: true
    },
    [AppRoutes.USERS_USERID]: {
        path: RoutePath.users_userId,
        element: <ProfilePage />,
        private: true
    }
}
