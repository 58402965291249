import { StoreSchema } from '../../../../shared/config/storeConfig/StoreSchema'
import {
    FunctionalitiesSet,
    LicenseState,
    LicenseType
} from '../../../../entities/License'

interface EditedLicenseDTO {
	expirationDate: string | undefined
	allowedVersions: string[]
	state: LicenseState,
	maxLiveInstances: number
	type: LicenseType
	functionalitiesSet?: FunctionalitiesSet
}

export const selectEditedLicenseData = (state: StoreSchema): EditedLicenseDTO => {

    const { license: { licenseEditable } } = state

    const productAllowedVersions = licenseEditable
        .allowedVersions as unknown as string

    /* eslint-disable */
	const allowedVersions = Array.isArray(productAllowedVersions)
		? productAllowedVersions
		: productAllowedVersions.length
			? productAllowedVersions.split(',')
			: []
	/* eslint-enable */

    const licenseExpirationDate = licenseEditable.expirationDate
    const expirationDate =
		typeof licenseExpirationDate === 'object'
		    ? licenseExpirationDate?.toISOString().slice(0, 10) +
			'T00:00:00.000Z'
		    : licenseExpirationDate

    const functionalitiesSet = licenseEditable.functionalitiesSet ?? {}

    return {
        expirationDate,
        allowedVersions,
        state: licenseEditable.state,
        maxLiveInstances: licenseEditable.maxLiveInstances,
        type: licenseEditable.type,
        functionalitiesSet
    }
}
