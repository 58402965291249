import { createSlice } from '@reduxjs/toolkit'
import { ClientsSchema } from '../types/Clients'
import { fetchClients } from '../services/fetchClients'

const initialState: ClientsSchema = {
    limit: 10,
    error: '',
    isLoading: false,
    paginatedClients: {
        currentPage: 1,
        items: []
    },
    queryFilter: {},
    filter: {},
    sort: {}
}
const clientsSlice = createSlice({
    name: 'clientsSlice',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.paginatedClients.currentPage = action.payload
        },
        setLimit: (state, action) => {
            state.limit = action.payload
        },
        setDirection: (state, action) => {
            state.sort.direction = action.payload
        },
        setSort: (state, action) => {
            state.sort.sort = action.payload
        },
        setQueryFilter: (state) => {
            state.queryFilter = { ...state.filter }
        },
        setCompanyName: (state, action) => {
            state.filter.companyName = action.payload
        },
        setManagerName: (state, action) => {
            state.filter.managerName = action.payload
        },
        setProductName: (state, action) => {
            state.filter.productName = action.payload
        },
        setState: (state, action) => {
            state.filter.state = action.payload
        },
        setCreationDateFrom: (state, action) => {
            state.filter.creationDateFrom = action.payload
        },
        setCreationDateTo: (state, action) => {
            state.filter.creationDateTo = action.payload
        },
        removeFilter: (state, action) => {
            const filterName: keyof typeof state.queryFilter = action.payload
            delete state.filter[filterName]
            delete state.queryFilter[filterName]
        },
        resetFilter: (state) => {
            state.filter = {}
            state.queryFilter = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchClients.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(fetchClients.fulfilled, (state, action) => {
                state.isLoading = false
                state.paginatedClients = action.payload
            })
            .addCase(fetchClients.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { actions: clientsActions } = clientsSlice
export const { reducer: clientsReducer } = clientsSlice
