import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModalSchema } from '../types/Modal'

const initialState: ModalSchema = {
    modalType: undefined,
    data: undefined,
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        showModal: (state, action: PayloadAction<ModalSchema>) => {
            state.modalType = action.payload.modalType
            state.data = action.payload.data
        },

        hideModal: (state) => {
            state.modalType = undefined
            state.data = undefined
        },
    },
})

export const modalActions = modalSlice.actions
export const modalReducer = modalSlice.reducer
