import { createAsyncThunk } from '@reduxjs/toolkit'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { selectLicenseId } from '../../../../entities/License'
import { fetchInstances } from '../../../../entities/Instances'

export const deleteInstance = createAsyncThunk<
	void,
	string,
	ThunkConfig<string>
>('deleteInstance', async (instanceId, thunkAPI) => {

    const { dispatch, extra: { api }, rejectWithValue, getState } = thunkAPI
    const licenseId = selectLicenseId(getState())

    try {
        await api.delete(`/licenses/${licenseId}/instances/${instanceId}`)
        showToast({
            type: 'success',
            toastId: 'deleteInstance',
            message: 'Success'
        })

        dispatch(fetchInstances(licenseId))
    } catch (e) {
        console.log(e)
        appHandleError(e, 'deleteInstance', 'Failed to delete')
        return rejectWithValue('Error occurred on instance delete')
    }
})
