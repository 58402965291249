import { createSlice } from '@reduxjs/toolkit'
import { IUser, UserSchema } from '../types/User'
import { fetchProfileUser } from '../services/fetchProfileUser'
import { fetchUser } from '../services/fetchUser'
import { UserRightType } from '../types/UserRightType'

const user: IUser = {
	rights: {
		licenses: UserRightType.READ_ONLY,
		products: UserRightType.READ_ONLY,
		clients: UserRightType.READ_ONLY,
		users: UserRightType.READ_ONLY,
		instances: UserRightType.READ_ONLY,
		applications: UserRightType.READ_ONLY,
		externalServices: UserRightType.READ_ONLY
	},
	login: '',
	email: '',
	phone: '',
	fullName: '',
	position: '',
	isDeactivated: false,
	userId: ''
}

const initialState: UserSchema = {
	isLoading: false,
	error: '',
	userData: user,
	cachedUser: user
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setFullName: (state, action) => {
			state.userData.fullName = action.payload
		},
		setLogin: (state, action) => {
			state.userData.login = action.payload
		},
		setEmail: (state, action) => {
			state.userData.email = action.payload
		},
		setPosition: (state, action) => {
			state.userData.position = action.payload
		},
		setDepartmentName: (state, action) => {
			state.userData.departmentName = action.payload
		},
		setPhone: (state, action) => {
			state.userData.phone = action.payload
		},
		setTfbOffice: (state, action) => {
			state.userData.tfbOffice = action.payload
		},
		setRights: (state, action) => {
			state.userData.rights = action.payload
		},
		resetUser: (state) => {
			state.userData = state.cachedUser
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchProfileUser.pending, (state) => {
				state.isLoading = true
				state.error = ''
			})
			.addCase(fetchProfileUser.fulfilled, (state, action) => {
				state.isLoading = false
				state.userData = action.payload
				state.cachedUser = action.payload
			})
			.addCase(fetchProfileUser.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})
			.addCase(fetchUser.pending, (state) => {
				state.isLoading = true
				state.error = ''
			})
			.addCase(fetchUser.fulfilled, (state, action) => {
				state.isLoading = false
				state.userData = action.payload
				state.cachedUser = action.payload
			})
			.addCase(fetchUser.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})
	}
})

export const { actions: userActions } = userSlice
export const { reducer: userReducer } = userSlice
