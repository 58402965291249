import React, { useEffect, useState } from 'react'

function getSavedValue<T>(key: string, defaultValue: T): T {
	const savedValue = sessionStorage.getItem(key)
	return savedValue ? JSON.parse(savedValue) : defaultValue
}

export function useSessionStorage<T>(
	key: string,
	initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
	const [value, setValue] = useState(getSavedValue(key, initialValue))

	useEffect(() => {
		sessionStorage.setItem(key, JSON.stringify(value))
	}, [key, value])

	return [value, setValue]
}