import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { IInstance } from '../types/Instance'
import { mapInstances } from '../lib/mapInstances'
import { selectQueryFilter } from '../selectors/selectQueryFilter'
import { selectSortValues } from '../selectors/selectSortValues'
import { addQueryParams } from '../../../../shared/lib/addQueryParams/addQueryParams'

export const fetchInstances = createAsyncThunk<
	Array<IInstance>,
	string,
	ThunkConfig<string>
>('instances/fetchInstances', async (licenseId, thunkAPI) => {
	const {
		extra: { api },
		rejectWithValue,
		getState
	} = thunkAPI

	const queryFilters = selectQueryFilter(getState())
	const sortValues = selectSortValues(getState())

	const params = { ...queryFilters, ...sortValues }

	if (Object.keys(params).length) {
		addQueryParams(params)
	}

	try {
		const res = await api.get<Array<IInstance>>(
			`/licenses/${licenseId}/instances`,
			{ params: params }
		)

		return mapInstances(res.data)
	} catch (e) {
		console.log(e)
		appHandleError(e, 'fetchInstances', 'Failed to fetch license instances')
		return rejectWithValue('Error occurred on fetching license instances')
	}
})
