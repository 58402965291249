import React from 'react'
import {
    useAppDispatch
} from '../../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import {
    useAppSelector
} from '../../../../../shared/lib/hooks/useAppSelector/useAppSelector'
import { MTPermissions } from '../../../../../entities/MTPermissions/ui/MTPermissions'
import { productActions } from '../../../../../entities/Product'

export const ProductUpdateMT4Permissions = () => {
    const dispatch = useAppDispatch()

    const mt4PermissionsChosen = useAppSelector(
        (state) =>
            state.product.editableProduct.requiredPermissions.mt4Permissions
    )

    const dispatchOnMtPermissionsCheck = (mtPermissions: string[]) => {
        dispatch(productActions.setMT4Permissions(mtPermissions))
    }

    return (
        <MTPermissions
            mtPermissionsType="MT4 Permissions"
            mtPermissionsChosen={mt4PermissionsChosen}
            dispatchMtPermissions={dispatchOnMtPermissionsCheck}
        />
    )
}
