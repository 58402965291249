import { memo } from 'react'
import { MuiSelect } from '../../../shared/ui/MuiSelect/MuiSelect'
import { Role } from '../model/types/Role'
import { SelectChangeEvent } from '@mui/material/Select'
import { UserRights, UserRightType } from '../../User'
import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { newUserActions } from '../../../features/AddNewUser'
import { useAppSelector } from '../../../shared/lib/hooks/useAppSelector/useAppSelector'
import { selectRole } from '../model/selectors/selectRole'
import { roleActions } from '../model/slices/roleSlice'
import { SxProps } from '@mui/material'

const roleRights: Record<Role, UserRights> = {
	[Role.CUSTOM]: {
		licenses: UserRightType.READ_ONLY,
		products: UserRightType.READ_ONLY,
		clients: UserRightType.READ_ONLY,
		users: UserRightType.READ_ONLY,
		instances: UserRightType.READ_ONLY,
		applications: UserRightType.READ_ONLY,
		externalServices: UserRightType.READ_ONLY
	},
	[Role.QA]: {
		licenses: UserRightType.READ_ONLY,
		products: UserRightType.READ_ONLY,
		clients: UserRightType.NO_PERMISSION,
		users: UserRightType.NO_PERMISSION,
		instances: UserRightType.READ_ONLY,
		applications: UserRightType.READ_ONLY,
		externalServices: UserRightType.NO_PERMISSION
	},
	[Role.ADMIN]: {
		licenses: UserRightType.WRITE,
		products: UserRightType.WRITE,
		clients: UserRightType.WRITE,
		users: UserRightType.WRITE,
		instances: UserRightType.WRITE,
		applications: UserRightType.WRITE,
		externalServices: UserRightType.WRITE
	},
	[Role.SALES]: {
		licenses: UserRightType.WRITE,
		products: UserRightType.READ_ONLY,
		clients: UserRightType.WRITE,
		users: UserRightType.NO_PERMISSION,
		instances: UserRightType.WRITE,
		applications: UserRightType.NO_PERMISSION,
		externalServices: UserRightType.NO_PERMISSION
	},
	[Role.SUCCESS]: {
		licenses: UserRightType.WRITE,
		products: UserRightType.READ_ONLY,
		clients: UserRightType.WRITE,
		users: UserRightType.NO_PERMISSION,
		instances: UserRightType.WRITE,
		applications: UserRightType.NO_PERMISSION,
		externalServices: UserRightType.NO_PERMISSION
	},
	[Role.INTERN]: {
		licenses: UserRightType.READ_ONLY,
		products: UserRightType.READ_ONLY,
		clients: UserRightType.NO_PERMISSION,
		users: UserRightType.NO_PERMISSION,
		instances: UserRightType.READ_ONLY,
		applications: UserRightType.NO_PERMISSION,
		externalServices: UserRightType.NO_PERMISSION
	},
	[Role.SUPPORT]: {
		licenses: UserRightType.WRITE,
		products: UserRightType.READ_ONLY,
		clients: UserRightType.READ_ONLY,
		users: UserRightType.NO_PERMISSION,
		instances: UserRightType.WRITE,
		applications: UserRightType.WRITE,
		externalServices: UserRightType.NO_PERMISSION
	},
	[Role.ANALYST]: {
		licenses: UserRightType.READ_ONLY,
		products: UserRightType.WRITE,
		clients: UserRightType.NO_PERMISSION,
		users: UserRightType.NO_PERMISSION,
		instances: UserRightType.NO_PERMISSION,
		applications: UserRightType.READ_ONLY,
		externalServices: UserRightType.NO_PERMISSION
	},
	[Role.DEVELOPER]: {
		licenses: UserRightType.NO_PERMISSION,
		products: UserRightType.READ_ONLY,
		clients: UserRightType.NO_PERMISSION,
		users: UserRightType.NO_PERMISSION,
		instances: UserRightType.NO_PERMISSION,
		applications: UserRightType.READ_ONLY,
		externalServices: UserRightType.NO_PERMISSION
	},
	[Role.TECH_WRITER]: {
		licenses: UserRightType.READ_ONLY,
		products: UserRightType.READ_ONLY,
		clients: UserRightType.NO_PERMISSION,
		users: UserRightType.NO_PERMISSION,
		instances: UserRightType.NO_PERMISSION,
		applications: UserRightType.READ_ONLY,
		externalServices: UserRightType.NO_PERMISSION
	}
}

export const RoleSelect = memo(({ styles }: { styles: SxProps }) => {
	const dispatch = useAppDispatch()
	const role = useAppSelector(selectRole)

	const handleRoleChange = (e: SelectChangeEvent) => {
		const chosenRole = e.target.value as Role
		dispatch(roleActions.setRole(chosenRole))

		const chosenRoleRights = roleRights[chosenRole]
		dispatch(newUserActions.setUserRights(chosenRoleRights))
	}

	return (
		<MuiSelect
			handleSelectChange={handleRoleChange}
			label="Role"
			labelId="role"
			selectOptions={Role}
			value={role}
			size="medium"
			styles={styles}
		/>
	)
})
