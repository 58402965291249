import { LICENSE_UNLIMITED } from '../consts'

export const isExpirationUnlimited = (date: Date | string | null | undefined) => {
	return Boolean(
		date && (
			typeof date === 'string'
				? date.includes(LICENSE_UNLIMITED)
				: date instanceof Date
					? `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`.includes(LICENSE_UNLIMITED)
					: false
		)
	)
}