export function getQueryParams(
    params: OptionalRecord<string, number | string>
) {
    const searchParams = new URLSearchParams(window.location.search)
    const searchParamsEntries = searchParams.entries()
    const paramsEntries = Object.entries(params)

    paramsEntries.forEach(([paramName, value]) => {
        if (value !== undefined) {
            searchParams.set(paramName, value.toString())
        }
        if (value === '') {
            searchParams.delete(paramName)
        }
    })

    const paramsToDelete = []
    // @ts-ignore
    for (const [searchParam] of searchParamsEntries) {
        const paramNotInFilters = params[searchParam] === undefined
        if (paramNotInFilters) {
            paramsToDelete.push(searchParam)
        }
    }
    paramsToDelete.forEach(param => searchParams.delete(param))

    return `?${searchParams.toString()}`
}

export function addQueryParams(
    params: OptionalRecord<string, number | string>
) {
    window.history.pushState(null, '', getQueryParams(params))
}
