import { memo, useMemo } from 'react'
import { SxProps } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

const style = { width: '14rem' }

interface MuiAutocompleteProps {
	onInputChange: (value: string) => void
	value: string | undefined
	options: Array<string>
	label: string
	styles?: SxProps
	className?: string
}

export const MuiAutocomplete = memo((props: MuiAutocompleteProps) => {
    const {
        onInputChange,
        options,
        label,
        value,
        styles = {},
        className
    } = props

    const sxStyles = useMemo(() => ({ ...style, ...styles }), [styles])

    return (
        <Autocomplete
            sx={sxStyles}
            value={value ?? ''}
            className={className}
            onInputChange={(e, v) => {
                onInputChange?.(v)
            }}
            options={options.map((option) => option)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label={label}
                    InputProps={{
                        ...params.InputProps
                    }}
                />
            )}
        />
    )
})
