import { createAsyncThunk } from '@reduxjs/toolkit'
import { IUser } from '../../../../entities/User'
import { AUTH_USER_LS_KEY } from '../../../../entities/AuthUser/model/constants/constants'
import { authUserActions } from '../../../../entities/AuthUser'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { loginActions } from '../slices/loginSlice'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import {
    mapRightsNullToNoPermission
} from '../../../../shared/lib/mappers/mapRightsNullToNoPermission'
import { mapCSuite } from '../../../../shared/lib/mappers/mapCSuite'

export interface LoginServiceProps {
	login: string
	password: string
}

export const loginService = createAsyncThunk<
	IUser,
	LoginServiceProps,
	ThunkConfig<string>
>('login/loginService', async ({ login, password }, thunkAPI) => {

    const { dispatch, extra: { api }, rejectWithValue } = thunkAPI

    try {
        const { data: user } = await api.post<IUser>('/login', { login, password })

        const authUser = mapRightsNullToNoPermission(user)
        mapCSuite(authUser)
        localStorage.setItem(AUTH_USER_LS_KEY, JSON.stringify(authUser))

        dispatch(authUserActions.setAuthData(authUser))
        dispatch(loginActions.resetLoginState())

        return authUser
    } catch (e: any) {
        console.log(e)
        showToast({
            message: e.response?.data?.message || e.message,
            type: 'error'
        })
        return rejectWithValue(e.response?.data?.message || e.message)
    }
})
