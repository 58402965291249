import { createAsyncThunk } from '@reduxjs/toolkit'
import { ILicenseWithUpdates } from '../types/License'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import {
    formFunctionalitiesWithDisplayName
} from '../lib/mappers/formFunctionalitiesWithDisplayName'

export const fetchLicense = createAsyncThunk<
	ILicenseWithUpdates,
	string,
	ThunkConfig<string>
>('license/fetchLicense', async (licenseId, thunkAPI) => {

    const { extra: { api }, rejectWithValue } = thunkAPI

    try {
        const { data } = await api.get<ILicenseWithUpdates>(`/licenses/${licenseId}`)
        const functionalitiesWithDisplayName = formFunctionalitiesWithDisplayName(data)

        return { ...data, functionalitiesWithDisplayName }
    } catch (e) {
        console.log(e)
        appHandleError(e, 'fetchLicense', 'Failed to fetch license data')
        return rejectWithValue('Error occurred on fetching license data')
    }
})
