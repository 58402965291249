import { classNames } from '../../../shared/lib/classNames/classNames'
import classes from './OnErrorPage.module.scss'
import Button from '@mui/material/Button'

export const OnErrorPage = () => {
    const reloadThePage = () => {
        // eslint-disable-next-line no-restricted-globals
        location.reload()
    }
    return (
        <div className={classNames(classes['on-error-page'], {}, [])}>
            <h4 className="mb-4">Unexpected error occurred</h4>
            <Button
                size="large"
                variant="outlined"
                onClick={reloadThePage}
            >
                Refresh
            </Button>
        </div>
    )
}
