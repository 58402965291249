import { createSlice } from '@reduxjs/toolkit'
import { LoginSchema } from '../types/LoginSchema'
import { loginService } from '../services/loginService'

const initialState: LoginSchema = {
    isLoading: false,
    login: '',
    password: '',
    error: '',
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        resetLoginState: (state) => {
            state.login = ''
            state.password = ''
        },
        setLogin: (state, action) => {
            state.login = action.payload
        },
        setPassword: (state, action) => {
            state.password = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginService.pending, (state) => {
                state.error = undefined
                state.isLoading = true
            })
            .addCase(loginService.fulfilled, (state) => {
                state.isLoading = false
            })
            .addCase(loginService.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    },
})

export const loginActions = loginSlice.actions
export const loginReducer = loginSlice.reducer
