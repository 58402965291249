export type Mods = Record<string, string | boolean | undefined>

export const classNames = (
    classes: string,
    mods: Mods = {},
    additionalClasses: Array<string | undefined> = []
): string =>
    [
        classes,

        ...Object.entries(mods)
            .filter(([_, value]) => Boolean(value))
            .map(([className]) => className),

        ...additionalClasses.filter((el) => Boolean(el)),
    ].join(' ')
