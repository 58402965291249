import { createSlice } from '@reduxjs/toolkit'
import { INewUser, NewUserSchema } from '../types/NewUser'
import { addNewUser } from '../service/addNewUser'
import { UserRightType } from '../../../../entities/User'

const newUser: INewUser = {
	rights: {
		users: UserRightType.READ_ONLY,
		clients: UserRightType.READ_ONLY,
		licenses: UserRightType.READ_ONLY,
		products: UserRightType.READ_ONLY,
		instances: UserRightType.READ_ONLY,
		applications: UserRightType.READ_ONLY,
		externalServices: UserRightType.READ_ONLY
	},
	login: '',
	email: '',
	phone: '',
	fullName: '',
	position: '',
	password: ''
}

const initialState: NewUserSchema = {
	isLoading: false,
	error: '',
	newUser
}

const newUserSlice = createSlice({
	name: 'newUserState',
	initialState,
	reducers: {
		resetNewUser: (state) => {
			state.newUser = newUser
		},
		setFullName: (state, action) => {
			state.newUser.fullName = action.payload
		},
		setLogin: (state, action) => {
			state.newUser.login = action.payload
		},
		setPassword: (state, action) => {
			state.newUser.password = action.payload
		},
		setUserRights: (state, action) => {
			state.newUser.rights = action.payload
		},
		setEmail: (state, action) => {
			state.newUser.email = action.payload
		},
		setPosition: (state, action) => {
			state.newUser.position = action.payload
		},
		setDepartmentName: (state, action) => {
			state.newUser.departmentName = action.payload
		},
		setPhone: (state, action) => {
			state.newUser.phone = action.payload
		},
		setTfbOffice: (state, action) => {
			state.newUser.tfbOffice = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addNewUser.pending, (state) => {
				state.isLoading = true
				state.error = ''
			})
			.addCase(addNewUser.fulfilled, (state) => {
				state.isLoading = false
				state.error = ''
			})
			.addCase(addNewUser.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})
	}
})

export const { actions: newUserActions } = newUserSlice
export const { reducer: newUserReducer } = newUserSlice
