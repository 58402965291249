import { useMemo } from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useController, UseControllerProps } from 'react-hook-form'

const style = { minWidth: '12rem', m: '0' }

type SelectOption = string;
export type SelectOptions = SelectOption[] | Record<string, SelectOption>;

interface MuiSelectValidateProps extends UseControllerProps {
	onSelect: (e: SelectChangeEvent) => void,
	selectOptions: SelectOptions
	labelId: string
	label: string
	styles?: SxProps
	required?: boolean
	disabled?: boolean
}

export const MuiSelectValidate = (props: MuiSelectValidateProps) => {
    const {
        selectOptions,
        onSelect,
        labelId,
        label,
        styles = {},
        required = true,
        disabled = false
    } = props

    const { field, fieldState } = useController(props)

    const handleSelect = (e: SelectChangeEvent) => {
        field.onChange(e)
        onSelect(e)
    }

    const sxStyle = useMemo(() => ({ ...style, ...styles }), [styles])

    const options = useMemo(() => {
        return Array.isArray(selectOptions)
            ? selectOptions
            : Object.keys(selectOptions) as (keyof typeof selectOptions)[]
    }, [selectOptions])

    const optionItems = useMemo(() => {
        return options.map((option) => (
            <MenuItem
                key={option}
                value={Array.isArray(selectOptions) ? option : selectOptions[option]}
            >
                {option}
            </MenuItem>
        ))
    }, [options, selectOptions])

    return (
        <Box sx={sxStyle}>
            <FormControl
                fullWidth
                required={required}
                disabled={disabled}
                error={!!fieldState?.error?.message}
            >
                <InputLabel id={labelId}>{label}</InputLabel>
                <Select
                    {...field}
                    label={label}
                    labelId={labelId}
                    onChange={handleSelect}
                >
                    {options && optionItems}
                </Select>
                <FormHelperText>{!!fieldState?.error?.message}</FormHelperText>
            </FormControl>
        </Box>
    )
}
