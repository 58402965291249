import { Suspense, useCallback } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import {
    AppRoutesProps,
    routeConfig
} from '../../../../shared/config/routeConfig/routeConfig'
import { Loader } from '../../../../shared/ui/Loader/Loader'
import { PrivateRoute } from './PrivateRoute'

export const AppRouter = () => {
	const renderWithWrapper = useCallback((route: AppRoutesProps) => {
		const element = (
			<Suspense fallback={<Loader />}>
				<div className="page-container">
					<div className="page-wrapper">
						{route.element}
					</div>
				</div>
			</Suspense>
		)

		return (
			<Route
				key={route.path}
				path={route.path}
				element={
					route.private ? (
						<PrivateRoute route={route}>{element}</PrivateRoute>
					) : (
						element
					)
				}
			/>
		)
	}, [])

	const routes = Object.values(routeConfig).map(renderWithWrapper)

	routes.push(
		<Route
			key="/"
			path="/"
			element={
				<Navigate
					to={routeConfig.profile.path!}
					replace
				/>
			}
		/>
	)

	return <Routes>{routes}</Routes>
}
