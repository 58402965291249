import { toast, TypeOptions } from 'react-toastify'

interface ToastProps {
    message?: string
    type?: TypeOptions
    toastId?: string
}

export const showToast = (props: ToastProps): void => {
    const { message, type, toastId } = props

    toast(message, {
        type,
        toastId,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
    })
}
