import { memo } from 'react'
import Box from '@mui/material/Box'
import { ProductUpdateMT4Permissions } from './Mt4Permissions/ProductUpdateMT4Permissions'
import { ProductUpdateMT5Permissions } from './MT5Permissions/ProductUpdateMT5Permissions'

export const ProductPermissions = memo(() => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				width: '100%'
			}}
		>
			<ProductUpdateMT4Permissions />
			<ProductUpdateMT5Permissions />
		</Box>
	)
})
