import { Direction } from '../../../../shared/types/Direction'

export interface MtInfo {
	serverName: string
	serverOwner: string
	updateTime: string
}

export interface Ip {
	ip: string
	updateTime: string
}

export enum InstanceState {
	ACTIVE = 'active',
	DEACTIVATED = 'deactivated'
}


export enum InstanceLabel {
	LIVE = 'live',
	DEMO = 'demo',
	BACKUP = 'backup',
	TECHNICAL = 'technical'
}

export interface InstancesBrief {
	instanceId: string
	instanceName: string
	ip: Array<Ip>
}

export interface IInstance extends InstancesBrief {
	mtInfo: Array<MtInfo>
	productVersion: string
	componentName: string
	state: InstanceState
	lastRenewalTime: string
	initializationTime: string
	label: InstanceLabel
}

export interface InstancesFilter {
	instanceName?: string
	label?: InstanceLabel
	ip?: string
	productVersion?: string
}

export interface InstancesSchema {
	instancesData: Array<IInstance>

	queryFilter: InstancesFilter,
	filter: InstancesFilter
	sort: {
		direction?: Direction
		sort?: string
	}
	isLoading: boolean
	error?: string
}
