import { IInstance } from '../types/Instance'

export const mapInstances = (instances: IInstance[]) => {
    return instances.map((instance) => {
        const initializationTime = instance.initializationTime
            .slice(0, 19)
            .split('T')
            .join(' ')

        const lastRenewalTime = instance.lastRenewalTime
            .slice(0, 19)
            .split('T')
            .join(' ')

        const mtInfo = instance.mtInfo.map((el) => {
            const mtInfoUpdateTime = el.updateTime
                .slice(0, 19)
                .split('T')
                .join(' ')
            return { ...el, updateTime: mtInfoUpdateTime }
        })

        const instanceName = instance.instanceName
			?? mtInfo[0]?.serverName
			?? ''

        return { ...instance, instanceName, initializationTime, lastRenewalTime, mtInfo }
    })
}
