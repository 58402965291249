import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NewFunctionalitiesSchema } from '../types/NewFunctionalitiesSchema'
import { v4 as uuidv4 } from 'uuid'
import { FunctionalitiesTemplatesModified } from '../../../../entities/Functionality'

const emptyFunctionality: FunctionalitiesTemplatesModified = {
    functionalityTemplateId: uuidv4(),
    functionalityName: '',
    displayName: '',
    productId: '',
    values: [{ valueId: uuidv4(), value: '' }],
    type: 'enum'
}

const initialState: NewFunctionalitiesSchema = {
    functionalities: [emptyFunctionality],
    isLoading: false,
    error: ''
}

const addFunctionalitiesSlice = createSlice(
    {
        name: 'addFunctionalitiesSlice',
        initialState: initialState,
        reducers: {
            resetState: (state) => {
                state.functionalities = [emptyFunctionality]
            },
            setEmptyFunctionality: (state) => {
                state.functionalities.push({
                    ...emptyFunctionality,
                    functionalityTemplateId: uuidv4(),
                    values: [{ valueId: uuidv4(), value: '' }]
                })
            },
            removeFunctionality: (state, action: PayloadAction<number>) => {
                state.functionalities.splice(action.payload, 1)
            },
            setEmptyFunctionalityValue: (
                state,
                action: PayloadAction<number>
            ) => {
                state.functionalities[action.payload]?.values?.push({
                    valueId: uuidv4(),
                    value: ''
                })
            },
            removeFunctionalityValue: (
                state,
                action: PayloadAction<{ funcIndex: number, valueId: string }>
            ) => {
                const { funcIndex, valueId } = action.payload
                state.functionalities[funcIndex].values = state
                    .functionalities[funcIndex]?.values.filter((value) => value.valueId !== valueId)
            },
            setFunctionalityName: (
                state,
                action: PayloadAction<{ funcName: string, funcIndex: number }>
            ) => {
                const { funcIndex, funcName } = action.payload
                state.functionalities[funcIndex].functionalityName = funcName
            },
            setFunctionalityDisplayName: (
                state,
                action: PayloadAction<{ displayName: string, funcIndex: number }>
            ) => {
                const { funcIndex, displayName } = action.payload
                state.functionalities[funcIndex].displayName = displayName
            },
            setFunctionalityValue: (
                state,
                action: PayloadAction<{ value: string, funcIndex: number, valueId: string }>
            ) => {
                const { value, funcIndex, valueId } = action.payload
                state.functionalities[funcIndex].values = state.functionalities[funcIndex].values
                    .map(valueData => {
                        if (valueData.valueId === valueId) {
                            return { ...valueData, value: value }
                        }
                        return valueData
                    })
            }
        }
    }
)

export const { reducer: addFunctionalitiesReducer } = addFunctionalitiesSlice
export const { actions: addFunctionalitiesActions } = addFunctionalitiesSlice
