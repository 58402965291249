import { createAsyncThunk } from '@reduxjs/toolkit'
import { CommonProduct } from '../types/Common'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'

export const fetchCommonProducts = createAsyncThunk<
    Array<CommonProduct>,
    void,
    ThunkConfig<string>
>('common/fetchCommonProducts', async (_, thunkAPI) => {
    const {
        extra: { api },
        rejectWithValue,
    } = thunkAPI

    try {
        const res = await api.get<Array<CommonProduct>>('/common/products')

        return res.data
    } catch (e) {
        console.log(e)
        appHandleError(e, 'fetchCommonData', 'Failed to fetch common data')
        return rejectWithValue('Error occurred ')
    }
})
