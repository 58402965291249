import React, { memo, useMemo } from 'react'
import TextField from '@mui/material/TextField'
import { SxProps } from '@mui/material'

const style = { width: '14rem' }

interface MuiInputProps<T> {
	handleChange: (e: any) => void
	value: T
	label: string
	inputType: 'text' | 'number' | 'password' | 'email'
	className?: string
	required?: boolean
	disabled?: boolean
	size?: 'small' | 'medium'
	styles?: SxProps
}

export const MuiInput = memo(<T extends {}>(props: MuiInputProps<T>) => {
    const {
        handleChange,
        value,
        label,
        inputType,
        className,
        required = false,
        disabled = false,
        styles = {},
        size = 'small',
        ...rest
    } = props

    const sxStyle = useMemo(() => ({ ...style, ...styles }), [styles])

    return (
        <TextField
            type={inputType}
            sx={sxStyle}
            label={label}
            variant="outlined"
            required={required}
            disabled={disabled}
            className={className}
            onChange={handleChange}
            value={value || ''}
            size={size}
            {...rest}
        />
    )
}) as <T>(props: MuiInputProps<T>) => JSX.Element
