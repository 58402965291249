import { memo } from 'react'
import { isExpirationUnlimited } from '../model/lib/utils/isExpirationUnlimited'
import { TEXT_UNLIMITED } from '../model/lib/consts'

interface ExpirationDateInTableProps {
	expirationDate: string
}

export const ExpirationDateInTable = memo((props: ExpirationDateInTableProps) => {
	const { expirationDate } = props

	const result = isExpirationUnlimited(expirationDate) ? TEXT_UNLIMITED : expirationDate

	return <>{result}</>
})