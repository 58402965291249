import { showToast } from '../../../../shared/lib/showToast/showToast'
import fileDownload from 'js-file-download'
import { camelToSentence } from '../../../../shared/lib/caseConverters/camelToSentence'

export function exportDataToCsv(keys: string[], data: any[]) {
	const separator = ';'
	const headerFields = keys.map(key => camelToSentence(key))
	const sortedData = [headerFields]

	for (const dataElement of data) {
		const rowData = keys.map((key) => {
			const fieldValue = dataElement[key]

			if (fieldValue && typeof fieldValue === 'object') {
				showToast({
					message: 'Export failed. Unserializable data provided',
					type: 'error',
					toastId: 'instances-export-to-csv'
				})
				throw new Error('Export data unserializable')
			}
			if (String(fieldValue).includes(separator)) {
				showToast({
					message: 'Export failed. Data contains semicolon',
					type: 'error',
					toastId: 'instances-export-to-csv'
				})
				throw new Error('Export data contains semicolon')
			}
			return fieldValue
		})
		sortedData.push(rowData)
	}

	const csvContent = sortedData.reduce((acc: string, cur: string[]) => {
		acc += cur.join(separator) + '\n'
		return acc
	}, '')

	const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' })

	const fileName = 'instances.csv'
	fileDownload(csvBlob, fileName)
}