import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../../../shared/lib/hooks/useAuth/useAuth'
import {
    AppRoutesProps,
    RoutePath
} from '../../../../shared/config/routeConfig/routeConfig'
import { UserRightType } from '../../../../entities/User'

export interface PrivateRouteProps {
	route: AppRoutesProps,
	children: JSX.Element
}

export function PrivateRoute(props: PrivateRouteProps) {
    const { route, children } = props
    const location = useLocation()
    const { authUser } = useAuth()

    let restrictRoute = false
    const authUserRightsEntries = authUser ? Object.entries(authUser.rights) : []

    for (const [right, rightType] of authUserRightsEntries) {
        if (route.path?.includes(String(right)) && rightType === UserRightType.NO_PERMISSION) {
            restrictRoute = true
        }
    }

    const appLocations = Object.values(RoutePath)
    const validLocation = appLocations.includes(location.pathname)

    return authUser ? (
        restrictRoute ? (<Navigate to={RoutePath.not_found} />) : children
    ) : (
        <Navigate
            to={RoutePath.login}
            state={{
                from: validLocation
                    ? location.pathname
                    : RoutePath.profile
            }}
            replace
        />
    )
}
