import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../shared/lib/appHandleError/appHandleError'
import { fetchComments } from './fetchComments'

export const updateComment = createAsyncThunk<
	void,
	{ objectId: string, commentId: string; text: string, type: string },
	ThunkConfig<string>
>('comments/updateComment', async (obj, thunkAPI) => {
    const { dispatch, extra: { api }, rejectWithValue } = thunkAPI
    const { objectId, commentId, text, type } = obj

    try {
        await api.put(`/comments/${commentId}`, { text })

        dispatch(fetchComments({ objectId, type }))
    } catch (e) {
        console.log(e)
        appHandleError(e, 'updateComment', 'Failed to update comment')
        return rejectWithValue('Error occurred on updating a comment')
    }
})
