import { typedObjectEntries } from '../utils/typedObjectEntries'
import { typedReduce } from '../utils/typedReduce'
import { IUser, UserRights, UserRightType } from '../../../entities/User'

type ValueOf<T> = T[keyof T]

export const mapRightsNullToNoPermission = (user: IUser): IUser => {
	const rights: UserRights = {
		users: UserRightType.NO_PERMISSION,
		clients: UserRightType.NO_PERMISSION,
		products: UserRightType.NO_PERMISSION,
		licenses: UserRightType.NO_PERMISSION,
		instances: UserRightType.NO_PERMISSION,
		applications: UserRightType.NO_PERMISSION,
		externalServices: UserRightType.NO_PERMISSION
	}

	const mappedRights = typedReduce(
		// @ts-ignore
		typedObjectEntries(user.rights),
		(acc: UserRights, [k, v]: [keyof UserRights, ValueOf<UserRights>]) => {
			return { ...acc, [k]: v }
		},
		rights
	)

	user.rights = mappedRights

	return user
}


