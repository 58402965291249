import { createAsyncThunk } from '@reduxjs/toolkit'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { fetchCommonProducts } from '../../../../entities/Common'
import { fetchProducts } from '../../../../pages/ProductsPage'
import { selectNewProduct } from '../selectors/selectNewProduct'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'

export const addNewProduct = createAsyncThunk<string, void, ThunkConfig<string>>(
    'newProductState/addNewProduct',
    async (_, thunkAPI) => {

        const { dispatch, extra: { api }, rejectWithValue, getState } = thunkAPI

        const newProduct = selectNewProduct(getState())
        const productNameTrimmed = newProduct.name?.trim()
        const newProductDTO = { ...newProduct, name: productNameTrimmed }

        try {
            const { data: { objectId } } = await api.post('/products', newProductDTO)

            showToast({
                type: 'success',
                toastId: 'newProductCreated',
                message: 'New product created'
            })

            dispatch(fetchProducts())
            dispatch(fetchCommonProducts())

            return objectId?.length ? objectId : ''
        } catch (e) {
            console.log(e)
            appHandleError(e, 'addNewProduct', 'Failed to add a new product')
            return rejectWithValue('Error occurred while adding a new product')
        }
    }
)
