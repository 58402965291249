import { ILicense } from '../model/types/License'
import Tooltip from '@mui/material/Tooltip'
import { CurrentInstancesMarked } from './CurrentInstancesMarked'

interface CurrentInstancesProps {
	license: ILicense
	side: 'right' | 'left'
}

export const CurrentInstances = (props: CurrentInstancesProps) => {

    const { license, side } = props

    const currentInstances = license.numberOfInstances
    const isMaxLiveInstancesExceeded = license.isMaxLiveInstancesExceeded

    if (isMaxLiveInstancesExceeded) return (
        <>
            {side === 'left' && currentInstances}
            <Tooltip
                placement="top"
                title="Number of instances exceeds the maximum allowed"
            >
                <CurrentInstancesMarked />
            </Tooltip>
            {side === 'right' && currentInstances}
        </>
    )

    return <>{currentInstances}</>
}
