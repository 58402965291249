import { memo } from 'react'
import { AppLink, AppLinkTheme } from '../../../shared/ui/AppLink/AppLink'
import { classNames } from '../../../shared/lib/classNames/classNames'
import Typography from '@mui/material/Typography'
import classes from './NotFoundPage.module.scss'
import { RoutePath } from '../../../shared/config/routeConfig/routeConfig'

interface NotFoundPageProps {
	className?: string
}

export const NotFoundPage = memo((props: NotFoundPageProps) => {
    const { className } = props

    return (
        <div className={classNames(classes['not-found-page'], {}, [className])}>
            <Typography
                sx={{ mb: '2rem', fontWeight: 600 }}
                component="h4"
                variant="h4"
            >
				Page is not found
            </Typography>
            <AppLink
                to={RoutePath.profile}
                theme={AppLinkTheme.STANDARD}
            >
				Proceed to profile page
            </AppLink>
        </div>
    )
})
