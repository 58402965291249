import React, { BaseSyntheticEvent, memo, PropsWithChildren, useMemo } from 'react'
import { modalActions } from '../../../entities/Modal'
import { useAppDispatch } from '../../lib/hooks/useAppDispatch/useAppDispatch'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 900,
	bgcolor: 'background.paper',
	borderRadius: 2,
	p: '2rem'
}

interface ModalFormProps extends PropsWithChildren {
	handleOnSubmit: (e: BaseSyntheticEvent) => any
	modalTitle: string
	handleCancel: () => void
	disabled: boolean
	styles?: SxProps
	hideBtns?: boolean
}

export const ModalForm = memo((props: ModalFormProps) => {
	const {
		handleOnSubmit,
		modalTitle,
		styles,
		handleCancel,
		disabled,
		hideBtns = false,
		children
	} = props
	const dispatch = useAppDispatch()

	const handleClose = () => {
		dispatch(modalActions.hideModal())
	}

	const sxStyle = useMemo(() => ({ ...style, ...styles }), [styles])

	return (
		<Modal
			open={true}
			onClose={handleClose}
		>
			<Box sx={sxStyle}>
				<Typography
					sx={{ mb: '1rem', fontWeight: 600 }}
					variant="h5"
					component="h5"
				>
					{modalTitle}
				</Typography>
				<form onSubmit={handleOnSubmit}>
					{children}
					{!hideBtns && (
						<Box
							sx={{
								mt: '1rem',
								display: 'flex',
								justifyContent: 'flex-end'
							}}
						>
							<Button
								color="secondary"
								variant="contained"
								onClick={handleCancel}
							>
								Cancel
							</Button>
							<Button
								sx={{ ml: '0.5rem' }}
								disabled={disabled}
								variant="contained"
								type="submit"
							>
								Apply
							</Button>
						</Box>
					)}
				</form>
			</Box>
		</Modal>
	)
})
