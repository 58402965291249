import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { IClient } from '../types/Client'
import { getManagersNames } from '../lib/getManagersNames'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'

export const fetchClient = createAsyncThunk<
    { client: IClient; managerNames: string[] },
    string,
    ThunkConfig<string>
>('fetchClient', async (clientId, thunkApi) => {
    const {
        extra: { api },
        rejectWithValue,
    } = thunkApi

    try {
        const res = await api.get<IClient>(`/clients/${clientId}`)

        const managerNames = getManagersNames(res.data)

        return { client: res.data, managerNames }
    } catch (e) {
        console.log(e)
        appHandleError(e, 'fetchClient', 'Failed to fetch client data')
        return rejectWithValue('Error occurred on fetching client')
    }
})
