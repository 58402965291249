import React, { ChangeEvent, memo, useCallback } from 'react'
import {
    useAppDispatch
} from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import {
    useAppSelector
} from '../../../../shared/lib/hooks/useAppSelector/useAppSelector'
import {
    ProductComponentsInputs
} from '../../../../shared/ui/Inputs/ProjectsInput/ProductComponentsInputs'
import { Component, productActions } from '../../../../entities/Product'

export const ProductComponentsUpdate = memo(() => {
    const dispatch = useAppDispatch()
    const components = useAppSelector(
        (state) => state.product.editableProduct.components
    )

    const handleInputChange = useCallback((
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        i: number
    ) => {
        dispatch(
            productActions.setComponents({
                name: e.target.name as keyof Component,
                value: e.target.value,
                index: i
            })
        )
    }, [dispatch])

    const handleRemoveComponent = useCallback((i: number) => {
        dispatch(productActions.removeComponent(i))
    }, [dispatch])

    const handleAddNewComponent = useCallback(() => {
        dispatch(productActions.setNewComponent())
    }, [dispatch])

    return (
        <ProductComponentsInputs
            components={components}
            handleInputChange={handleInputChange}
            handleAddNewComponent={handleAddNewComponent}
            handleRemoveComponent={handleRemoveComponent}
        />
    )
})
