import React, { forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faExclamationCircle
} from '@fortawesome/free-solid-svg-icons/faExclamationCircle'

export const CurrentInstancesMarked = forwardRef<any>(function CurrentInstancesMarked(props, ref) {
    return (
        <span {...props} ref={ref}>
            <FontAwesomeIcon
	            icon={faExclamationCircle}
	            style={{ margin: '0 4px' }}
	            color="#4B6687"
            />
        </span>
    )
})
