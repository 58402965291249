import React, { memo, SyntheticEvent, useMemo } from 'react'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import {
    useAppDispatch
} from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import {
    useAppSelector
} from '../../../../shared/lib/hooks/useAppSelector/useAppSelector'
import { selectCommonProducts } from '../../../../entities/Common'
import { productActions } from '../../../../entities/Product'

export const ProductsCombinedWith = memo(() => {
    const dispatch = useAppDispatch()

    const combinedWithProducts = useAppSelector(
        (state) => state.product.editableProduct.combinedWithProducts
    )
    const commonProducts = useAppSelector(selectCommonProducts)

    const productsOptions = useMemo(() => {
        return commonProducts.map((product) => product.name)
    }, [commonProducts])

    const handleCombinedWithProductsChange = (
        e: SyntheticEvent<Element, Event>,
        value: string[]
    ) => {
        dispatch(productActions.setCombineWithProducts(value))
    }

    return (
        <Autocomplete
            multiple
            freeSolo
            sx={{ minWidth: '49%', maxWidth: '49%' }}
            options={productsOptions}
            value={combinedWithProducts}
            onChange={handleCombinedWithProductsChange}
            renderTags={(value: string[], getTagProps) =>
                value.map((option, index: number) => (
                    <Chip
                        variant="filled"
                        color="primary"
                        label={option}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    label="Combined with products"
                    name="combinedWithProducts"
                />
            )}
        />
    )
})
