import { memo } from 'react'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { AppLink } from '../../../../shared/ui/AppLink/AppLink'
import { SvgIcon } from '../../../../shared/ui/SvgIcon/SvgIcon'
import { SidebarItemType } from '../../model/sidebarItemList'
import classes from './SidebarItem.module.scss'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../../shared/lib/hooks/useAuth/useAuth'
import { UserRights, UserRightType } from '../../../../entities/User'

interface SidebarItemProps {
	sidebarOpen: boolean
	item: SidebarItemType
	className?: string
	onClick?: () => void
}

export const SidebarItem = memo((props: SidebarItemProps) => {
    const { sidebarOpen, item, className, onClick } = props
    const { authUser } = useAuth()
    const location = useLocation()

    const routed = location.pathname === item.path
    const mods = { [classes.routed]: routed }

    const permission = authUser?.rights[item.path.slice(1) as keyof UserRights]

    if (permission === UserRightType.NO_PERMISSION) return <></>

    return (
        <AppLink
            to={item.path}
            onClick={onClick}
            className={classNames(classes['sidebar-item'], mods, [className])}
        >
            <div className={classes['icon-wrapper']}>
                <SvgIcon
                    className={classes['sidebar-item-icon']}
                    icon={item.icon}
                />
                {sidebarOpen && <span>{item.text}</span>}
            </div>
        </AppLink>
    )
})
