import { RoutePath } from '../../../shared/config/routeConfig/routeConfig'
import {
    faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard'
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons/faPeopleGroup'
import { faUsersGear } from '@fortawesome/free-solid-svg-icons/faUsersGear'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { faCubes } from '@fortawesome/free-solid-svg-icons/faCubes'
import { faFileCircleCheck } from '@fortawesome/free-solid-svg-icons/faFileCircleCheck'

// import { faSitemap } from "@fortawesome/free-solid-svg-icons/faSitemap";

export interface SidebarItemType {
	path: string
	text: string
	icon: IconDefinition
}

export const logoutItem: SidebarItemType = {
    path: RoutePath.login,
    text: 'Logout',
    icon: faArrowUpRightFromSquare
}

export const sidebarItemList: Array<SidebarItemType> = [
    {
        path: RoutePath.profile,
        text: 'Profile',
        icon: faAddressCard
    },
    {
        path: RoutePath.licenses,
        text: 'Licenses',
        icon: faFileCircleCheck
    },
    {
        path: RoutePath.clients,
        text: 'Clients',
        icon: faPeopleGroup
    },
    {
        path: RoutePath.products,
        text: 'Products',
        icon: faCubes
    },
    {
        path: RoutePath.users,
        text: 'Users',
        icon: faUsersGear
    }
    // {
    //     path: RoutePath.dependencies,
    //     text: 'Dependencies',
    //     icon: faSitemap,
    // },
]
