import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import 'react-toastify/dist/ReactToastify.css'
import { ErrorBoundary } from './app/providers/ErrorBoundary'
import { store } from './app/providers/Store'
import './app/styles/index.scss'
import { App } from './app/App'
import { muiTheme } from './app/providers/Theme/muiTheme'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={muiTheme}>
                <ErrorBoundary>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </ErrorBoundary>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
)
