import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CommentsSchema, IComment } from '../types/Comment'
import { fetchComments } from '../services/fetchComments'
import { deleteComment } from '../services/deleteComment'

const initialState: CommentsSchema = {
    comments: [],
    isLoading: false,
    error: ''
}

const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        addComment: (state, action: PayloadAction<IComment>) => {
            state.comments.push(action.payload)
        },
        resetComments: (state) => {
            state.comments = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchComments.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(fetchComments.fulfilled, (state, action) => {
                state.isLoading = false
                state.comments = action.payload
            })
            .addCase(fetchComments.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
            .addCase(deleteComment.fulfilled, (state, action) => {
                state.isLoading = false
                state.comments.splice(
                    state.comments.findIndex(
                        (comment) => comment.commentId === action.payload
                    ),
                    1
                )
            })
    }
})

export const { actions: commentsActions } = commentsSlice
export const { reducer: commentsReducer } = commentsSlice
