import { ChangeEvent, useCallback, useMemo } from 'react'
import { SxProps } from '@mui/material'
import { useController, UseControllerProps } from 'react-hook-form'
import TextField from '@mui/material/TextField'

const style = { minWidth: '12rem', m: '0' }

interface MuiInputValidateProps extends UseControllerProps {
	onChange: (e: ChangeEvent<HTMLInputElement>) => void,
	label: string
	styles?: SxProps
	required?: boolean
	disabled?: boolean
	size?: 'small' | 'medium'
}

export const MuiInputValidate = (props: MuiInputValidateProps) => {
    const {
        onChange,
        label,
        styles = {},
        required = true,
        disabled = false,
        size = 'small'
    } = props

    const { field, fieldState } = useController(props)

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        field.onChange(e)
        onChange(e)
    }, [field, onChange])

    const sxStyle = useMemo(() => ({ ...style, ...styles }), [styles])

    return (
        <TextField
            {...field}
            fullWidth
            size={size}
            sx={sxStyle}
            label={label}
            required={required}
            disabled={disabled}
            onChange={handleChange}
            error={!!fieldState?.error?.message}
            helperText={fieldState?.error?.message}
        />
    )
}
