export enum Department {
	C_SUITE = 'c-suite',
	FINANCE = 'finance',
	HR = 'hr',
	PAMM = 'pamm',
	SALES = 'sales',
	SUCCESS = 'success',
	SUPPORT = 'support',
	TOOLBOX = 'toolbox',
	TP = 'tp',
	WL = 'wl',
	OTHERS = 'other'
}
