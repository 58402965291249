import { memo, useCallback, useMemo } from 'react'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import {
	useAppDispatch
} from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { logoutUser } from '../../../../entities/AuthUser/model/services/logoutUser'
import { logoutItem, sidebarItemList } from '../../model/sidebarItemList'
import { SidebarItem } from '../SidebarItem/SidebarItem'
import classes from './Sidebar.module.scss'
import Tooltip from '@mui/material/Tooltip'

interface SidebarProps {
	sidebarOpen: boolean;
	className?: string;
}

export const Sidebar = memo((props: SidebarProps) => {
	const { sidebarOpen, className } = props
	const dispatch = useAppDispatch()

	const handleLogout = useCallback(() => {
		dispatch(logoutUser())
	}, [dispatch])

	const logoutButton = useMemo(
		() => (
			<SidebarItem
				item={logoutItem}
				sidebarOpen={sidebarOpen}
				onClick={handleLogout}
			/>
		),
		[sidebarOpen, handleLogout]
	)

	const sidebarItems = useMemo(
		() =>
			sidebarItemList.map((item) => sidebarOpen ? (
				<SidebarItem
					key={item.path}
					item={item}
					sidebarOpen={sidebarOpen}
				/>
			) : (
				<Tooltip
					key={item.path}
					title={item.text}
					placement="right"
				>
                    <span>
                        <SidebarItem
	                        item={item}
	                        sidebarOpen={sidebarOpen}
                        />
                    </span>
				</Tooltip>
			)),
		[sidebarOpen]
	)

	const mods = {
		[classes.open]: sidebarOpen
	}

	return (
		<div className={classNames(classes['sidebar'], mods, [className])}>
			<div className={classes['sidebar-items']}>{sidebarItems}</div>
			<div className={classes['logout-button']}>{logoutButton}</div>
		</div>
	)
})
