import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../shared/lib/appHandleError/appHandleError'

export const deleteComment = createAsyncThunk<
	string,
	string,
	ThunkConfig<string>
>(
    'comments/deleteComment',
    async (commentId, thunkAPI) => {

        const { extra: { api }, rejectWithValue } = thunkAPI

        try {
            await api.delete(`/comments/${commentId}`)

            return commentId
        } catch (e) {
            console.log(e)
            appHandleError(e, 'deleteComment', 'Failed to delete the comment')
            return rejectWithValue('Error occurred on deleting a comment')
        }
    })
