import { IClient } from '../types/Client'

export const getManagersNames = (client: IClient) => {
    const managers = client.managersBrief

    return managers.reduce(
        (acc: string[], manager) => [...acc, manager.fullName],
        []
    )
}
