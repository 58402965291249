import { IProduct, IProductEditable } from '../../types/Product'

export const mapProductPermissions = (
    product: IProduct
): IProductEditable => {
    const mt4Permissions = product?.mt4Permissions.slice(0)
    const mt5Permissions = product?.mt5Permissions.slice(0)

    return {
        ...product,
        requiredPermissions: {
            mt4Permissions,
            mt5Permissions
        }
    }
}
