import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { selectEditedLicenseData } from '../selectors/selectEditedLicenseData'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import { fetchLicense } from '../../../../entities/License'

export const updateLicense = createAsyncThunk<
	void,
	string,
	ThunkConfig<string>
>('updateLicense', async (licenseId, thunkAPI) => {

    const { dispatch, extra: { api }, getState, rejectWithValue } = thunkAPI
    const editedLicenseDTO = selectEditedLicenseData(getState())

    try {
        await api.put(`/licenses/${licenseId}`, editedLicenseDTO)

        showToast({
            type: 'success',
            toastId: 'updateLicense',
            message: 'Success'
        })

        dispatch(fetchLicense(licenseId))
    } catch (e) {
        console.log(e)
        appHandleError(e, 'updateLicense', 'License update failed')
        return rejectWithValue('Error occurred on license update')
    }
})
