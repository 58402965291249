import { memo, useCallback } from 'react'
import { CloudLink } from '../../../../../entities/CloudLink/ui/CloudLink'
import { Control } from 'react-hook-form'
import {
    useAppDispatch
} from '../../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { productActions } from '../../../../../entities/Product'

interface ProductCloudLinkProps {
	control: Control
}

export const ProductCloudLink = memo(({ control }: ProductCloudLinkProps) => {
    const dispatch = useAppDispatch()

    const handleCloudLinkChange = useCallback((cloudLink: string) => {
        dispatch(productActions.setCloudLink(cloudLink))
    }, [dispatch])

    return <CloudLink control={control} onCloudLinkChange={handleCloudLinkChange} />
})
