import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthUserSchema } from '../types/AuthUser'
import { IUser } from '../../../User'
import { AUTH_USER_LS_KEY } from '../constants/constants'
import { logoutUser } from '../services/logoutUser'

const userFromLocal = localStorage.getItem(AUTH_USER_LS_KEY)

const initialState: AuthUserSchema = {
    isLoading: false,
    error: '',
    authUser: userFromLocal ? JSON.parse(userFromLocal) : undefined,
}

const authUserSlice = createSlice({
    name: 'authUser',
    initialState,
    reducers: {
        setAuthData: (state, action: PayloadAction<IUser>) => {
            state.authUser = action.payload
        },
        initAuthData: (state) => {
            const user = localStorage.getItem(AUTH_USER_LS_KEY)
            if (user) {
                state.authUser = JSON.parse(user)
            }
        },
        logout: (state) => {
            state.authUser = undefined
            localStorage.removeItem(AUTH_USER_LS_KEY)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logoutUser.fulfilled, (state) => {
            state.authUser = undefined
        })
    },
})

export const { actions: authUserActions } = authUserSlice
export const { reducer: authUserReducer } = authUserSlice
