import { createAsyncThunk } from '@reduxjs/toolkit'
import { IComment } from '../types/Comment'
import { ThunkConfig } from '../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../shared/lib/appHandleError/appHandleError'

export const fetchComments = createAsyncThunk<
	Array<IComment>,
	{ objectId: string, type: string },
	ThunkConfig<string>
>('comments/fetchComments', async (args, thunkAPI) => {

    const { extra: { api }, rejectWithValue } = thunkAPI
    const { objectId, type } = args

    try {
        const res = await api.get<Array<IComment>>('/comments', {
            params: { objectId, resourceType: type }
        })

        return res.data

    } catch (e) {
        console.log(e)
        appHandleError(e, 'fetchComments', 'Failed to fetch comments')
        return rejectWithValue('Error occurred on fetching comments')
    }
})
