export interface Manager {
	userId: string
	fullName: string
	email: string
	position: string
	departmentName: string
}

export enum ClientState {
	ACTIVE = 'active',
	ON_HOLD_DEBTOR = 'onHoldDebtor',
	ON_HOLD_BY_REQUEST = 'onHoldByRequest',
	CONTRACT_CANCELLATION = 'contractCancellation',
}

export interface IClient {
	clientId: string
	tfbOffice: string
	state: ClientState
	numberOfProducts: 0
	creationDate: string
	companyNames: string[]
	managersBrief: Array<Manager>
}

export interface ClientSchema {
	clientData: IClient
	cachedClient: IClient
	managerNames: string[]
	isLoading: boolean
	error?: string
}
