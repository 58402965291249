import React, { memo } from 'react'
import {
    useAppDispatch
} from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import {
    useAppSelector
} from '../../../../shared/lib/hooks/useAppSelector/useAppSelector'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { productActions } from '../../../../entities/Product'

export const ProductUpdateCheckboxes = memo(() => {
	const dispatch = useAppDispatch()

	const isApiDataUsed = useAppSelector(
		(state) => state.product.editableProduct.isApiDataUsed
	)
	const webConfiguratorCompatability = useAppSelector(
		(state) => state.product.editableProduct.isToolboxCompatible
	)

	const handleIsApiDataUsedChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		dispatch(productActions.setIsApiDataUsed(event.target.checked))
	}
	const handleWCFCompatibilityChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		dispatch(
			productActions.setWebConfiguratorCompatability(event.target.checked)
		)
	}

	return (
		<FormGroup>
			<Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '2rem' }}>
				<FormControlLabel
					label="Is api data used"
					control={
						<Checkbox
							name="isApiDataUsed"
							checked={isApiDataUsed}
							onChange={handleIsApiDataUsedChange}
						/>
					}
				/>
				<FormControlLabel
					label="Is compatible with Toolbox"
					control={
						<Checkbox
							name="compatibleWithWebConfigurator"
							checked={webConfiguratorCompatability}
							onChange={handleWCFCompatibilityChange}
						/>
					}
				/>
			</Box>
		</FormGroup>
	)
})
