import { memo } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { classNames } from '../../lib/classNames/classNames'
import classes from './AppLink.module.scss'

export enum AppLinkTheme {
	WHITE = 'white',
	RED = 'red',
	STANDARD = 'standard',
	CLEAR = 'clear',
	AZURE = 'azure'
}

interface AppLinkProps extends LinkProps {
	className?: string
	theme?: AppLinkTheme
	onClick?: () => void
}

export const AppLink = memo((props: AppLinkProps) => {
    const {
        to,
        children,
        className,
        theme = AppLinkTheme.WHITE,
        onClick,
        ...restProps
    } = props

    return (
        <Link
            to={to}
            className={classNames(classes['app-link'], {}, [
                className,
                classes[theme]
            ])}
            onClick={onClick}
            {...restProps}
        >
            {children}
        </Link>
    )
})
