import { memo, useCallback } from 'react'
import { CustomChip } from '../../../../shared/ui/CustomChip/CustomChip'
import { camelToSentence } from '../../../../shared/lib/caseConverters/camelToSentence'
import {
	formatFilterValue
} from '../../../../shared/lib/formatFilterValue/formatFilterValue'
import {
	useAppDispatch
} from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import Box from '@mui/material/Box'
import { instancesActions } from '../../model/slices/instancesSlice'
import { fetchInstances } from '../../model/srevices/fetchInstances'
import { useParams } from 'react-router-dom'

interface InstancesFilterChipProps {
	filterKey: string
	filterValue: string
	licenseId: string
}

export const InstancesFilterChip = memo((props: InstancesFilterChipProps) => {
	const { filterKey, filterValue, licenseId } = props

	const dispatch = useAppDispatch()
	const { licenseId: licIDFromParams } = useParams()

	const filterKeyConverted = camelToSentence(filterKey)
	const filterValueConverted = formatFilterValue(filterValue)

	const deleteFilter = useCallback(() => {
		dispatch(instancesActions.removeFilter(filterKey))
		dispatch(fetchInstances(licIDFromParams ?? licenseId))
	}, [dispatch, filterKey, licIDFromParams, licenseId])

	return (
		<CustomChip onClick={deleteFilter} iconX>
			{`${filterKeyConverted}:`}
			<Box
				component="span"
				sx={{ fontWeight: 600, pl: '4px' }}
			>
				{filterValueConverted}
			</Box>
		</CustomChip>
	)
})