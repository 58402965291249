import { createSlice } from '@reduxjs/toolkit'
import { ProductsSchema } from '../types/Products'
import { fetchProducts } from '../services/fetchProducts'

const initialState: ProductsSchema = {
    isLoading: false,
    error: '',

    limit: 10,
    paginatedProducts: {
        currentPage: 1,
        items: []
    },
    queryFilter: {},
    filter: {},
    sort: {}
}

const productsSlice = createSlice({
    name: 'productsSlice',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.paginatedProducts.currentPage = action.payload
        },
        setLimit: (state, action) => {
            state.limit = action.payload
        },
        setDirection: (state, action) => {
            state.sort.direction = action.payload
        },
        setState: (state, action) => {
            state.state = action.payload
        },
        setSort: (state, action) => {
            state.sort.sort = action.payload
        },
        setQueryFilter: (state) => {
            state.queryFilter = { ...state.filter }
        },
        setKey: (state, action) => {
            state.filter.key = action.payload
        },
        setTier: (state, action) => {
            state.filter.tier = action.payload
        },
        setPlatform: (state, action) => {
            state.filter.platform = action.payload
        },
        setProductName: (state, action) => {
            state.filter.productName = action.payload
        },
        setYearOfDevelopment: (state, action) => {
            state.filter.yearOfDevelopment = action.payload
        },
        removeFilter: (state, action) => {
            const filterName: keyof typeof state.queryFilter = action.payload
            delete state.filter[filterName]
            delete state.queryFilter[filterName]
        },
        resetFilter: (state) => {
            state.filter = {}
            state.queryFilter = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.isLoading = false
                state.paginatedProducts = action.payload
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { actions: productsActions } = productsSlice
export const { reducer: productsReducer } = productsSlice
