import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { modalActions, ModalTypes } from '../../../../entities/Modal'

export const getLicenseFileLink = createAsyncThunk<
	void,
	string,
	ThunkConfig<string>
>(
    'getLicenseFileLink',
    async (licenseId, thunkAPI) => {

        const { dispatch, extra: { api }, rejectWithValue } = thunkAPI

        try {
            const response = await api.post<{ link: string }>(`/download/licenses/${licenseId}`)

            dispatch(modalActions.showModal({
                modalType: ModalTypes.LICENSE_FILE_LINK_MODAL,
                data: response.data
            }))

        } catch (e) {
            console.log(e)
            appHandleError(e, 'getLicenseFileLink', 'Failed')
            return rejectWithValue('Error occurred on getting license file link')
        }
    })
