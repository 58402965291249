import { ChangeEvent, memo, useCallback } from 'react'
import { MuiInput } from '../../../shared/ui/Inputs/MuiInput/MuiInput'
import Box from '@mui/material/Box'
import { MuiSelect } from '../../../shared/ui/MuiSelect/MuiSelect'
import { FilterApply } from '../../../shared/ui/FilterApply/FilterApply'
import { useAppSelector } from '../../../shared/lib/hooks/useAppSelector/useAppSelector'
import {
	selectInstanceName,
	selectIp,
	selectLabel,
	selectProductVersion
} from '../model/selectors/selectFilterValues'
import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { instancesActions } from '../model/slices/instancesSlice'
import { InstanceName } from './InstanceName/InstanceName'
import { InstanceLabel } from '../model/types/Instance'
import { SelectChangeEvent } from '@mui/material/Select'
import { fetchInstances } from '../model/srevices/fetchInstances'
import Stack from '@mui/material/Stack'

const inputStyles = { width: '16rem' }

interface InstancesFilterProps {
	licenseId: string
}

export const InstancesFilter = memo((props: InstancesFilterProps) => {
	const { licenseId } = props
	const dispatch = useAppDispatch()

	const instanceName = useAppSelector(selectInstanceName)
	const label = useAppSelector(selectLabel)
	const ip = useAppSelector(selectIp)
	const productVersion = useAppSelector(selectProductVersion)

	const handleInstanceNameFilter = useCallback((value: string) => {
		dispatch(instancesActions.setInstanceName(value))
	}, [dispatch])

	const handleLabelFilter = useCallback((e: SelectChangeEvent) => {
		dispatch(instancesActions.setLabel(e.target.value))
	}, [dispatch])

	const handleIpFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		dispatch(instancesActions.setIp(e.target.value))
	}, [dispatch])

	const handleProductVersionFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		dispatch(instancesActions.setProductVersion(e.target.value))
	}, [dispatch])

	const handleApply = useCallback(() => {
		dispatch(instancesActions.setQueryFilter())
		dispatch(fetchInstances(licenseId))
	}, [dispatch, licenseId])

	return (
		<Stack
			spacing={2}
			direction="row"
			sx={{ my: 2 }}
		>
			<InstanceName
				dispatcher={handleInstanceNameFilter}
				value={instanceName}
				styles={inputStyles}
			/>
			<MuiSelect
				handleSelectChange={handleLabelFilter}
				labelId={'instance-label-filter'}
				selectOptions={InstanceLabel}
				value={label}
				styles={inputStyles}
				label={'Label'}
			/>
			<MuiInput
				handleChange={handleIpFilter}
				styles={inputStyles}
				inputType={'text'}
				label={'Ip'}
				value={ip}
			/>
			<MuiInput
				handleChange={handleProductVersionFilter}
				styles={inputStyles}
				inputType={'text'}
				label={'Product version'}
				value={productVersion}
			/>
			<Box sx={inputStyles}>
				<FilterApply handleApply={handleApply} />
			</Box>
		</Stack>
	)
})