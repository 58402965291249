import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Role, RoleSchema } from '../types/Role'

const initialState: RoleSchema = { role: Role.CUSTOM }

const roleSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setRole: (state, action: PayloadAction<Role>) => {
            state.role = action.payload
        },
        resetRole: (state) => {
            state.role = Role.CUSTOM
        }
    }
})

export const { actions: roleActions } = roleSlice
export const { reducer: roleReducer } = roleSlice
