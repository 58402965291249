import { ChangeEvent, memo } from 'react'
import { MuiInputValidate } from '../../../shared/ui/Inputs/MuiInput/MuiInputControlled'
import { Control } from 'react-hook-form'

interface CloudLinkProps {
	control: Control
	onCloudLinkChange: (cloudLink: string) => void
}

export const CloudLink = memo((props: CloudLinkProps) => {
    const { onCloudLinkChange, control } = props

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onCloudLinkChange(e.target.value)
    }

    return (
        <MuiInputValidate
            onChange={handleChange}
            label={'Cloud folder link'}
            name={'cloudLink'}
            control={control}
            size={'medium'}
            rules={{
                required: 'This field is required',
                pattern: {
                    value: /^(http|https):\/\//,
                    message: 'Must be a valid url'
                }
            }}
        />
    )
})
