import { createAsyncThunk } from '@reduxjs/toolkit'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { addQueryParams } from '../../../../shared/lib/addQueryParams/addQueryParams'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { PaginatedData } from '../../../../shared/types/PaginatedData'
import { Manager } from '../../../../entities/Client/model/types/Client'
import { selectLimit } from '../selectors/selectLimit'
import { selectPage } from '../selectors/selectPage'
import { IClient } from '../../../../entities/Client'
import { selectSortValues } from '../selectors/selectSortValues'
import { selectQueryFilter } from '../selectors/selectQueryFilter'

export const fetchClients = createAsyncThunk<
	PaginatedData<IClient>,
	void,
	ThunkConfig<string>
>('clientsSlice/fetchClients', async (_, thunkAPI) => {

    const { extra: { api }, rejectWithValue, getState } = thunkAPI

    const page = selectPage(getState())
    const limit = selectLimit(getState())
    const sortValues = selectSortValues(getState())
    const queryFilter = selectQueryFilter(getState())
    const params = { ...queryFilter, ...sortValues, page, limit }

    addQueryParams(params)
    try {
        const { data } = await api.get<PaginatedData<IClient>>(
            '/clients',
            { params }
        )

        if (data) {
            data?.items?.forEach((item) => {
                item.creationDate = item.creationDate?.split('T')?.[0]

                const managers = item?.managersBrief
                    .reduce(
                        (acc: string[], manager) => [...acc, manager.fullName],
                        []
                    )
                    .join('; ')

                item.managersBrief = managers as unknown as Manager[]
            })
        }

        return data
    } catch (e) {
        console.log(e)
        appHandleError(e, 'fetchClients', 'Failed to fetch clients')
        return rejectWithValue('Error occurred on fetching clients list')
    }
})
