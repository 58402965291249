export const enum ModalTypes {
	ADD_NEW_USER_MODAL = 'ADD_NEW_USER_MODAL',
	ADD_NEW_CLIENT_MODAL = 'ADD_NEW_CLIENT_MODAL',
	ADD_NEW_PRODUCT_MODAL = 'ADD_NEW_PRODUCT_MODAL',
	ADD_NEW_LICENSE_MODAL = 'ADD_NEW_LICENSE_MODAL',
	DEACTIVATE_USER = 'DEACTIVATE_USER',
	DEACTIVATE_CLIENT = 'DEACTIVATE_CLIENT',
	DEACTIVATE_LICENSE = 'DEACTIVATE_LICENSE',
	DEACTIVATE_INSTANCE = 'DEACTIVATE_INSTANCE',
	CHANGE_PRODUCT_STATUS = 'CHANGE_PRODUCT_STATUS',
	ACTIVATE_INSTANCE = 'ACTIVATE_INSTANCE',
	ACTIVATE_LICENSE = 'ACTIVATE_LICENSE',
	ACTIVATE_PRODUCT = 'ACTIVATE_PRODUCT',
	ACTIVATE_CLIENT = 'ACTIVATE_CLIENT',
	ACTIVATE_USER = 'ACTIVATE_USER',
	GET_PRODUCT_PUBLIC_KEY = 'GET_PRODUCT_PUBLIC_KEY',
	UPDATE_PRODUCT = 'UPDATE_PRODUCT',
	UPDATE_LICENSE = 'UPDATE_LICENSE',
	UPDATE_CLIENT = 'UPDATE_CLIENT',
	UPDATE_USER_OR_PROFILE = 'UPDATE_USER_OR_PROFILE',
	DELETE_CLIENT_COMMENT_MODAL = 'DELETE_CLIENT_COMMENT_MODAL',
	DELETE_INSTANCE = 'DELETE_INSTANCE',
	DELETE_PRODUCT_FUNCTIONALITY = 'DELETE_PRODUCT_FUNCTIONALITY',
	ADD_PRODUCT_FUNCTIONALITIES = 'ADD_PRODUCT_FUNCTIONALITIES',
	EDIT_PRODUCT_FUNCTIONALITIES = 'EDIT_PRODUCT_FUNCTIONALITIES',
	EDIT_INSTANCE_NAME_MODAL = 'EDIT_INSTANCE_NAME_MODAL',
	RENEW_INSTANCE_LICENSE_MODAL = 'RENEW_INSTANCE_LICENSE_MODAL',
	MT_INFO_MODAL = 'MT_INFO_MODAL',
	LICENSE_FILE_LINK_MODAL = 'LICENSE_FILE_LINK_MODAL',
	DISPLAY_SIMILAR_NAMES_MODAL = 'DISPLAY_SIMILAR_NAMES_MODAL',
}

export interface ModalSchema {
	modalType?: ModalTypes
	data?: any
}
