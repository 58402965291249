import { createAsyncThunk } from '@reduxjs/toolkit'
import { authUserActions } from '../slices/authUserSlice'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
// import { AUTH_USER_LS_KEY } from "../constants/constants";

export const logoutUser = createAsyncThunk<void, void, ThunkConfig<string>>(
    'authUser/logoutUser',
    async (_, thunkAPI) => {
        const {
            dispatch,
            extra: { api },
            rejectWithValue,
        } = thunkAPI

        try {
            dispatch(authUserActions.logout())
            await api.post('/logout')
        } catch (e) {
            console.log(e)
            appHandleError(e, 'logoutUser', 'Error on logout')
            return rejectWithValue('Error occurred on logout')
        }
    }
)
