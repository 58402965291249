import { createAsyncThunk } from '@reduxjs/toolkit'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { fetchProduct } from '../../../../entities/Product/model/services/fetchProduct'
import { selectEditableProduct } from '../selectors/selectEditableProduct'
import { fetchCommonProducts } from '../../../../entities/Common'

export const updateProduct = createAsyncThunk<
	void,
	string,
	ThunkConfig<string>
>('updateProduct', async (productId, thunkAPI) => {

    const { dispatch, extra: { api }, rejectWithValue, getState } = thunkAPI
    const editedProductData = selectEditableProduct(getState())

    try {
        await api.put(`/products/${productId}`, editedProductData)
        showToast({
            type: 'success',
            toastId: 'updateProduct',
            message: 'Success'
        })

        dispatch(fetchProduct(productId))
        dispatch(fetchCommonProducts())
    } catch (e) {
        console.log(e)
        appHandleError(e, 'updateProduct', 'Product update failed')
        return rejectWithValue('Error occurred on product update')
    }
})
