import { memo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { classNames } from '../../lib/classNames/classNames'
import classes from './SvgIcon.module.scss'

interface SvgIconProps {
	icon: IconDefinition
	styles?: {}
	className?: string
}

export const SvgIcon = memo((props: SvgIconProps) => {
    const { icon, styles, className, ...restProps } = props

    return (
        <div
            style={styles || {}}
            className={classNames(classes['svg-icon'], {}, [className])}
            {...restProps}
        >
            <FontAwesomeIcon style={styles} icon={icon} />
        </div>
    )
})
