import { showToast } from '../showToast/showToast'
import { AUTH_USER_LS_KEY } from '../../../entities/AuthUser/model/constants/constants'

export const appHandleError = (e: any, toastId: string, msg: string) => {
    const type = 'error'

    if (e.response?.status === 401) {
        showToast({ type, toastId, message: e.response?.data?.message })
        localStorage.removeItem(AUTH_USER_LS_KEY)
        window.location.replace('/login')
        return
    }

    if (
        e.response?.data?.errors &&
        Object.keys(e?.response?.data?.errors)?.length
    ) {
        const errorKey = Object.keys(e.response.data.errors)[0]
        const message = e.response.data.errors[errorKey]

        return showToast({ type, message, toastId })
    }

    if (e.response?.data?.message) {
        return showToast({ type, toastId, message: e.response.data.message })
    }

    if (e.message)
        return showToast({ type, toastId, message: `${msg}. ${e.message}` })

    return e.statusText || e.status
}
