import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { IUser } from '../types/User'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import {
    mapRightsNullToNoPermission
} from '../../../../shared/lib/mappers/mapRightsNullToNoPermission'
import { mapCSuite } from '../../../../shared/lib/mappers/mapCSuite'

export const fetchProfileUser = createAsyncThunk<
	IUser,
	void,
	ThunkConfig<string>
>('user/fetchProfileUser', async (_, thunkApi) => {
    const {
        extra: { api },
        rejectWithValue
    } = thunkApi

    try {
        const { data } = await api.get<IUser>('/users/own')
        const userWithMappedRights = mapRightsNullToNoPermission(data)
        mapCSuite(userWithMappedRights)

        return userWithMappedRights
    } catch (e) {
        console.log(e)
        appHandleError(e, 'fetchProfileUser', 'Failed to fetch profile data')
        return rejectWithValue('Error occurred on fetching profile user data')
    }
})
