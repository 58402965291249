import { memo, useCallback } from 'react'
import { SxProps } from '@mui/material'
import {
	useAppSelector
} from '../../../../shared/lib/hooks/useAppSelector/useAppSelector'
import { selectInstanceNames } from '../../model/selectors/selectInstanceNames'
import { MuiAutocomplete } from '../../../../shared/ui/MuiAutocomplete/MuiAutocomplete'

interface InstanceNameProps {
	dispatcher: Function
	value: string | undefined
	styles: SxProps
}

export const InstanceName = memo((props: InstanceNameProps) => {
	const { dispatcher, value, styles } = props

	const instanceNameOptions = useAppSelector(selectInstanceNames)

	const handleInstanceNameInputChange = useCallback((value: string) => {
		dispatcher(value)
	}, [dispatcher])

	return (
		<MuiAutocomplete
			onInputChange={handleInstanceNameInputChange}
			options={instanceNameOptions}
			label={'Instance name'}
			styles={styles}
			value={value}
		/>
	)
})