import React, { memo, useMemo } from 'react'
import Button from '@mui/material/Button'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import { SxProps } from '@mui/material'

interface RemoveButtonProps {
	onRemove: () => void
	tooltipPlacement?: TooltipProps['placement']
	tooltipTitle?: string
	disabled?: boolean
	styles?: SxProps
}

const style = { color: 'red', p: 0, minWidth: '1rem' }

export const RemoveButton = memo((props: RemoveButtonProps) => {
	const {
		tooltipTitle,
		tooltipPlacement,
		onRemove,
		disabled = false,
		styles
	} = props

	const sxStyle = useMemo(() => ({ ...style, ...styles }), [styles])

	return (
		<Tooltip title={tooltipTitle} placement={tooltipPlacement}>
			<Button
				onClick={onRemove}
				disabled={disabled}
				sx={sxStyle}
			>
				<strong
					style={{ transform: 'rotate(45deg)' }}
				>
					+
				</strong>
			</Button>
		</Tooltip>
	)
})
