import { createAsyncThunk } from '@reduxjs/toolkit'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import { fetchUser } from '../../../../entities/User'
import { selectDataToUpdate } from '../selectors/selectDataToUpdate'
import { fetchCommonUsers } from '../../../../entities/Common'
import { mapC_Suit } from '../../../../shared/lib/mappers/mapC_Suit'

export const updateUser = createAsyncThunk<void, string, ThunkConfig<string>>(
    'updateUser',
    async (userId, thunkAPI) => {

        const { dispatch, extra: { api }, rejectWithValue, getState } = thunkAPI
        const userDataToUpdate = selectDataToUpdate(getState())
        const departmentName = mapC_Suit(userDataToUpdate.departmentName)
        const userDTO = { ...userDataToUpdate, departmentName }
        try {
            await api.put(`/users/${userId}`, userDTO)

            showToast({
                type: 'success',
                toastId: 'updateUser',
                message: 'Success'
            })

            dispatch(fetchUser(userId))
            dispatch(fetchCommonUsers())
        } catch (e) {
            console.log(e)
            appHandleError(e, 'updateUser', 'User update failed')
            return rejectWithValue('Error occurred on user data update')
        }
    }
)
