export enum MT4Permissions {
	MANAGER = 'manager',
	MONEY = 'money',
	ONLINE = 'online',
	RISKMAN = 'riskman',
	BROKER = 'broker',
	ADMIN = 'admin',
	LOGS = 'logs',
	REPORTS = 'reports',
	TRADES = 'trades',
	MARKET_WATCH = 'marketWatch',
	EMAIL = 'email',
	USER_DETAILS = 'userDetails',
	SEE_TRADES = 'seeTrades',
	NEWS = 'news',
	PLUGINS = 'plugins',
	SERVER_REPORTS = 'serverReports',
	TECHSUPPORT = 'techsupport',
	MARKET = 'market',
	NOTIFICATIONS = 'notifications',
}
