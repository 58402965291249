import { LicensesSchema } from '../types/Licenses'
import { createSlice } from '@reduxjs/toolkit'
import { fetchLicenses } from '../services/fetchLicenses'

const initialState: LicensesSchema = {
    limit: 10,
    isLoading: false,
    error: '',
    paginatedLicenses: {
        currentPage: 1,
        items: []
    },
    queryFilter: {},
    filter: {},
    sort: {}
}

const licensesSlice = createSlice({
    name: 'licenses',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.paginatedLicenses.currentPage = action.payload
        },
        setLimit: (state, action) => {
            state.limit = action.payload
        },
        setDirection: (state, action) => {
            state.sort.direction = action.payload
        },
        setSort: (state, action) => {
            state.sort.sort = action.payload
        },
        setType: (state, action) => {
            state.type = action.payload
        },
        setQueryFilter: (state) => {
            state.queryFilter = { ...state.filter }
        },
        setCompanyName: (state, action) => {
            state.filter.companyName = action.payload
        },
        setProductName: (state, action) => {
            state.filter.productName = action.payload
        },
        setManagerName: (state, action) => {
            state.filter.managerName = action.payload
        },
        setState: (state, action) => {
            state.filter.state = action.payload
        },
        setMaxLiveInstancesExceeded: (state, action) => {
            state.filter.maxLiveInstancesExceeded = action.payload
        },
        setCreationDateFrom: (state, action) => {
            state.filter.creationDateFrom = action.payload
        },
        setCreationDateTo: (state, action) => {
            state.filter.creationDateTo = action.payload
        },
        setExpirationDateFrom: (state, action) => {
            state.filter.expirationDateFrom = action.payload
        },
        setExpirationDateTo: (state, action) => {
            state.filter.expirationDateTo = action.payload
        },
        setServerIp: (state, action) => {
            state.filter.serverIp = action.payload
        },
        removeFilter: (state, action) => {
            const filterName: keyof typeof state.queryFilter = action.payload
            delete state.filter[filterName]
            delete state.queryFilter[filterName]
        },
        resetFilter: (state) => {
            state.filter = {}
            state.queryFilter = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLicenses.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(fetchLicenses.fulfilled, (state, action) => {
                state.isLoading = false
                state.paginatedLicenses = action.payload
            })
            .addCase(fetchLicenses.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { actions: licensesActions } = licensesSlice
export const { reducer: licensesReducer } = licensesSlice
