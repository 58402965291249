import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UsersSchema } from '../types/Users'
import { fetchUsers } from '../services/fetchUsers'
import { Department } from '../../../../entities/User'

const initialState: UsersSchema = {
    limit: 10,
    state: 'active',
    isLoading: false,
    error: '',
    paginatedUsers: {
        currentPage: 0,
        items: []
    },
    filters: {},
    queryFilters: {}
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.paginatedUsers.currentPage = action.payload
        },
        setLimit: (state, action) => {
            state.limit = action.payload
        },
        setState: (state, action) => {
            state.state = action.payload
        },
        setFullName: (state, action: PayloadAction<string>) => {
            state.filters.fullName = action.payload
        },
        setDepartmentName: (state, action: PayloadAction<Department>) => {
            state.filters.departmentName = action.payload
        },
        setQueryFilters: (state) => {
            state.queryFilters = { ...state.filters }
        },
        removeFilter: (state, action) => {
            const filterName: keyof typeof state.queryFilters = action.payload
            delete state.filters[filterName]
            delete state.queryFilters[filterName]
        },
        resetFilters: (state) => {
            state.filters = {}
            state.queryFilters = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.paginatedUsers = action.payload
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { actions: usersActions } = usersSlice
export const { reducer: usersReducer } = usersSlice
