import Box from '@mui/material/Box'
import { Step, StepLabel, Stepper } from '@mui/material'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import React, { PropsWithChildren } from 'react'


interface MuiStepperProps extends PropsWithChildren {
	steps: string[]
	activeStep: number
	setActiveStep: React.Dispatch<React.SetStateAction<number>>
	stepIsValid: boolean
}

export const MuiStepper = (props: MuiStepperProps) => {
	const {
		steps = [],
		activeStep,
		setActiveStep,
		stepIsValid,
		children
	} = props

	const lastStep = activeStep === steps.length - 1

	const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)
	const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

	return (
		<Box sx={{ width: '100%' }}>
			<Stepper
				activeStep={activeStep}
				sx={{ mb: 6 }}
			>
				{steps.map((label) => {
					const stepProps: { completed?: boolean } = {}
					const labelProps: {
						optional?: React.ReactNode;
					} = {}
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					)
				})}
			</Stepper>
			{activeStep === steps.length ? (
				<React.Fragment>
					<Typography sx={{ mt: 2, mb: 1 }}>
						All steps completed
					</Typography>
				</React.Fragment>
			) : (
				<>
					<Box>{children}</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							mt: 4
						}}
					>
						<Button
							color="secondary"
							variant="contained"
							disabled={activeStep === 0}
							onClick={handleBack}
							sx={{ mr: 1 }}
						>
							Back
						</Button>
						{!lastStep && (
							<Button
								variant="contained"
								disabled={stepIsValid}
								onClick={handleNext}
							>
								Next
							</Button>
						)}
						{lastStep && (
							<Button
								type="submit"
								variant="contained"
							>
								Apply
							</Button>
						)}
					</Box>
				</>
			)}
		</Box>
	)
}