import { createAsyncThunk } from '@reduxjs/toolkit'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { addQueryParams } from '../../../../shared/lib/addQueryParams/addQueryParams'
import { PaginatedData } from '../../../../shared/types/PaginatedData'
import { IProduct } from '../../../../entities/Product'
import { selectLimit } from '../selectors/selectLimit'
import { selectPage } from '../selectors/selectPage'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { selectSortValues } from '../selectors/selectSortValues'
import { selectQueryFilter } from '../selectors/selectQueryFilter'
import { selectProductsState } from '../selectors/selectProductsState'

export const fetchProducts = createAsyncThunk<
	PaginatedData<IProduct>,
	void,
	ThunkConfig<string>
>('productsSlice/fetchProducts', async (_, thunkAPI) => {

    const { extra: { api }, rejectWithValue, getState } = thunkAPI

    const page = selectPage(getState())
    const limit = selectLimit(getState())
    const state = selectProductsState(getState())
    const sortValues = selectSortValues(getState())
    const queryFilters = selectQueryFilter(getState())

    const params = { ...queryFilters, ...sortValues, page, limit, state }

    addQueryParams({ ...params })
    try {
        const response = await api.get<PaginatedData<IProduct>>(
            '/products',
            { params }
        )

        return response.data

    } catch (e) {
        console.log(e)
        appHandleError(e, 'fetchProducts', 'Failed to fetch "products" data')
        return rejectWithValue('Error occurred on fetching products list')
    }
})
