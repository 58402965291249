import React, { memo } from 'react'
import { SvgIcon } from '../../../../shared/ui/SvgIcon/SvgIcon'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv'
import Button from '@mui/material/Button'
import { exportDataToCsv } from '../../model/lib/exportDataToCsv'
import Tooltip from '@mui/material/Tooltip'
import { IInstance } from '../../model/types/Instance'
import { mapInstancesToExport } from '../../model/lib/mapInstancesToExport'

interface InstancesExporterProps {
	columnNames: string[]
	instances: IInstance[]
}

export const InstancesExporter = memo((props: InstancesExporterProps) => {
	const { columnNames, instances } = props

	const instancesMapped = mapInstancesToExport(instances)

	const handleExport = () => {
		exportDataToCsv(columnNames, instancesMapped)
	}

	return (
		<Tooltip title={'Export table data to csv'}>
			<Button
				color="info"
				size="small"
				variant="contained"
				sx={{ height: '1.9rem' }}
				onClick={handleExport}
			>
				<SvgIcon icon={faFileCsv} /> &nbsp;
			</Button>
		</Tooltip>
	)
})