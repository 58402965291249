import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'

export const getProductPublicKey = createAsyncThunk<
	string,
	string,
	ThunkConfig<string>
>(
    'productPublicKey/getProductPublicKey',
    async (productId, thunkAPI) => {

        const { extra: { api }, rejectWithValue } = thunkAPI

        try {
            const res = await api.get<{ publicKey: string }>(
                `/products/${productId}/key`
            )

            return res.data.publicKey
        } catch (e) {
            console.log(e)
            appHandleError(e, 'getProductPublicKey', 'Failed to get the key')
            return rejectWithValue('Error occurred on getting product public key')
        }
    })
