import { IInstance } from '../types/Instance'

export const mapInstancesToExport = (instances: IInstance[]) => instances.map(instance => {
	const mayBeIp = instance.ip?.[0]?.ip
	const ip = mayBeIp || ''

	return {
		instanceName: instance.instanceName,
		label: instance.label,
		ip: ip,
		componentName: instance.componentName,
		productVersion: instance.productVersion,
		initializationTime: instance.initializationTime,
		lastRenewalTime: instance.lastRenewalTime,
		state: instance.state
	}
})