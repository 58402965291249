import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SimilarNamesMapped, SimilarNamesSchema } from '../types/SimilarNames'
import { fetchSimilarNames } from '../services/fetchSimilarNames'

const initialState: SimilarNamesSchema = {
    similarNames: [],
    isLoading: false,
    error: ''
}

const similarNamesSlice = createSlice({
    name: 'similarNames',
    initialState,
    reducers: {
        resetSimilarNames: state => {
            state.similarNames = []
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSimilarNames.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(
                fetchSimilarNames.fulfilled,
                (state, action: PayloadAction<SimilarNamesMapped | string>) => {
                    state.isLoading = false
                    state.error = ''
                    if (Array.isArray(action.payload)) {
                        state.similarNames = action.payload
                    }
                })
            .addCase(fetchSimilarNames.rejected, (state, action) => {
                state.isLoading = true
                state.error = action.payload
            })
    }
})

export const { actions: similarNamesActions } = similarNamesSlice
export const { reducer: similarNamesReducer } = similarNamesSlice
