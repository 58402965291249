import React, { ChangeEvent, memo } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Component } from '../../../../entities/Product'
import { RemoveButton } from '../../RemoveButton/RemoveButton'

const inputStyle = { minWidth: '2rem', m: '0' }
const removeBtnStyles = { width: '2rem' }

interface ProjectsInputProps {
	components: Component[]
	handleInputChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, i: number) => void
	handleAddNewComponent: () => void
	handleRemoveComponent: (i: number) => void
}

export const ProductComponentsInputs = memo((props: ProjectsInputProps) => {
	const {
		components,
		handleAddNewComponent,
		handleRemoveComponent,
		handleInputChange
	} = props

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
			{components?.map((component, i) => (
				<Box
					key={i.toString()}
					sx={{
						mb: '0.5rem',
						display: 'flex',
						justifyContent: 'space-between',
						gap: '1rem'
					}}
				>
					<TextField
						sx={inputStyle}
						required
						size="small"
						name="componentName"
						label="Component name"
						value={component.componentName || ''}
						onChange={(e) => handleInputChange(e, i)}
					/>
					<TextField
						sx={inputStyle}
						required
						size="small"
						name="key"
						label="Key"
						value={component.key || ''}
						onChange={(e) => handleInputChange(e, i)}
					/>
					<TextField
						sx={inputStyle}
						required
						size="small"
						name="jiraLink"
						label="Jira link"
						value={component.jiraLink || ''}
						onChange={(e) => handleInputChange(e, i)}
					/>
					<TextField
						sx={inputStyle}
						required
						size="small"
						name="repositoryLink"
						label="Repository link"
						value={component.repositoryLink || ''}
						onChange={(e) => handleInputChange(e, i)}
					/>
					<RemoveButton
						onRemove={() => handleRemoveComponent(i)}
						disabled={components.length === 1}
						styles={removeBtnStyles}
					/>
				</Box>
			))}
			<Box>
				<Button
					size="small"
					variant="outlined"
					onClick={handleAddNewComponent}
				>
					+
				</Button>
				&nbsp; Add another component
			</Box>
		</Box>
	)
})
