import { memo } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const style = { m: '0', p: '0' }

interface AddFunctionalityBtnProps {
	onAddFunctionality: () => void
	className?: string;
}

export const AddFunctionalityBtn = memo((props: AddFunctionalityBtnProps) => {
    const {
        className,
        onAddFunctionality
    } = props

    return (
        <Box sx={{ display: 'flex', mt: '2rem' }}>
            <Typography sx={style}>Add new functionality</Typography>
            <Button
                sx={style}
                className={className}
                onClick={onAddFunctionality}
            >
				+
            </Button>
        </Box>
    )
})
