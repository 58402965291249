import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addNewLicense } from '../services/addNewLicense'
import { INewLicense, NewLicenseSchema } from '../types/NewLicense'
import { LicenseType } from '../../../../entities/License'
import { FunctionalityTemplate } from '../../../../entities/Functionality'

const newLicense: INewLicense = {
    clientId: '',
    productId: '',
    type: LicenseType._,
    allowedVersions: '',
    functionalitiesSet: {}
}

const initialState: NewLicenseSchema = {
    error: '',
    isLoading: false,
    newLicense: newLicense
}

const newLicenseSlice = createSlice({
    name: 'newLicense',
    initialState,
    reducers: {
        resetNewLicense: (state) => {
            state.newLicense = newLicense
            state.chosenProductFunctionalities = undefined
        },
        setClientId: (state, action) => {
            state.newLicense.clientId = action.payload
        },
        setProductId: (state, action) => {
            state.newLicense.productId = action.payload
        },
        setMaxLiveInstances: (state, action) => {
            state.newLicense.maxLiveInstances = action.payload
        },
        setType: (state, action) => {
            state.newLicense.type = action.payload
        },
        setExpirationDate: (state, action) => {
            state.newLicense.expirationDate = action.payload
        },
        setAllowedVersions: (state, action) => {
            state.newLicense.allowedVersions = action.payload
        },
        disableFunctionality: (
            state,
            action: PayloadAction<string>
        ) => {
            const { payload: functionalityId } = action
            delete state.newLicense.functionalitiesSet[functionalityId]
        },
        enableFunctionality: (
            state,
            action: PayloadAction<string>
        ) => {
            const { payload: functionalityId } = action
            state.newLicense.functionalitiesSet[functionalityId] = ''
        },
        setChosenProductFunctionalities: (
            state,
            action: PayloadAction<Array<FunctionalityTemplate>>
        ) => {
            state.chosenProductFunctionalities = action.payload
        },
        setFunctionalitiesSet: (
            state,
            action: PayloadAction<{ functionalityId: string, value: string }>
        ) => {
            const { functionalityId, value } = action.payload
            state.newLicense.functionalitiesSet[functionalityId] = value
        },
        resetFunctionalitiesSet: (state) => {
            state.newLicense.functionalitiesSet = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addNewLicense.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(addNewLicense.fulfilled, (state) => {
                state.isLoading = false
            })
            .addCase(addNewLicense.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { actions: newLicenseActions } = newLicenseSlice
export const { reducer: newLicenseReducer } = newLicenseSlice
