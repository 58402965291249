import { memo } from 'react'
import {
    useAppSelector
} from '../../../../shared/lib/hooks/useAppSelector/useAppSelector'
import { ModalTypes } from '../../../../entities/Modal'
import { AddNewUserModal } from '../../../../features/AddNewUser'
import { AddNewClientModal } from '../../../../features/AddNewClient'
import { UpdateClientModal } from '../../../../features/UpdateClient'
import { AddNewProductModal } from '../../../../features/AddNewProduct'
import { AddNewLicenseModal } from '../../../../features/AddNewLicense'
import { UpdateLicenseModal } from '../../../../features/UpdateLicense'
import { UpdateProductModal } from '../../../../features/UpdateProduct'
import { DeactivateUserModal } from '../../../../features/DeactivateUser'
import { DeleteInstanceModal } from '../../../../features/DeleteInstance'
import { DeactivateClientModal } from '../../../../features/DeactivateClient'
import { DeactivateLicenseModal } from '../../../../features/DeactivateLicense'
import { ProductPublicKeyModal } from '../../../../features/GetProductPublicKey'
import { ChangeProductStateModal } from '../../../../features/ChangeProductState'
import { DeleteClientCommentModal } from '../../../../features/DeleteClientComment'
import { UpdateUserOrProfileModal } from '../../../../features/UpdateUserOrProfile'
import { EditFunctionalitiesModal } from '../../../../features/EditFunctionalities'
import { LicenseFileLinkModal } from '../../../../features/GetLicenseFileLink'
import { EditInstanceNameModal } from '../../../../features/EditInstanceName'
import { ActivateLicenseModal } from '../../../../features/ActivateLicense'
import { ActivateClientModal } from '../../../../features/ActivateClient'
import { ActivateUserModal } from '../../../../features/ActivateUser'
import { ShowMTInfoModal } from '../../../../features/ShowMTInfo'
import {
    AddProductFunctionalitiesModal
} from '../../../../features/AddFunctionalitiesToProduct'
import { DeactivateInstanceModal } from '../../../../features/DeactivateInstance'
import { ActivateInstanceModal } from '../../../../features/ActivateInstance'
import {
    DeleteProductFunctionalityModal
} from '../../../../features/DeleteProductFunctionality'
import { DisplaySimilarNamesModal } from '../../../../features/DisplaySimilarNames'
import { RenewInstanceLicenseModal } from '../../../../features/RenewInstanceLicense'

export const Modals = memo(() => {
    const modal = useAppSelector((state) => state.modal) // @ts-ignore

    switch (modal.modalType) {
        case ModalTypes.ADD_NEW_USER_MODAL:
            return <AddNewUserModal />
        case ModalTypes.ADD_NEW_CLIENT_MODAL:
            return <AddNewClientModal />
        case ModalTypes.ADD_NEW_PRODUCT_MODAL:
            return <AddNewProductModal />
        case ModalTypes.ADD_NEW_LICENSE_MODAL:
            return <AddNewLicenseModal />
        case ModalTypes.DEACTIVATE_USER:
            return <DeactivateUserModal />
        case ModalTypes.DEACTIVATE_CLIENT:
            return <DeactivateClientModal />
        case ModalTypes.CHANGE_PRODUCT_STATUS:
            return <ChangeProductStateModal />
        case ModalTypes.DEACTIVATE_LICENSE:
            return <DeactivateLicenseModal />
        case ModalTypes.DEACTIVATE_INSTANCE:
            return <DeactivateInstanceModal />
        case ModalTypes.ACTIVATE_LICENSE:
            return <ActivateLicenseModal />
        case ModalTypes.ACTIVATE_INSTANCE:
            return <ActivateInstanceModal />
        case ModalTypes.ACTIVATE_CLIENT:
            return <ActivateClientModal />
        case ModalTypes.ACTIVATE_USER:
            return <ActivateUserModal />
        case ModalTypes.GET_PRODUCT_PUBLIC_KEY:
            return <ProductPublicKeyModal />
        case ModalTypes.UPDATE_PRODUCT:
            return <UpdateProductModal />
        case ModalTypes.UPDATE_LICENSE:
            return <UpdateLicenseModal />
        case ModalTypes.UPDATE_CLIENT:
            return <UpdateClientModal />
        case ModalTypes.UPDATE_USER_OR_PROFILE:
            return <UpdateUserOrProfileModal />
        case ModalTypes.DELETE_CLIENT_COMMENT_MODAL:
            return <DeleteClientCommentModal />
        case ModalTypes.DELETE_PRODUCT_FUNCTIONALITY:
            return <DeleteProductFunctionalityModal />
        case ModalTypes.DELETE_INSTANCE:
            return <DeleteInstanceModal />
        case ModalTypes.ADD_PRODUCT_FUNCTIONALITIES:
            return <AddProductFunctionalitiesModal />
        case ModalTypes.EDIT_PRODUCT_FUNCTIONALITIES:
            return <EditFunctionalitiesModal />
        case ModalTypes.EDIT_INSTANCE_NAME_MODAL:
            return <EditInstanceNameModal />
        case ModalTypes.RENEW_INSTANCE_LICENSE_MODAL:
            return <RenewInstanceLicenseModal />
        case ModalTypes.MT_INFO_MODAL:
            return <ShowMTInfoModal />
        case ModalTypes.LICENSE_FILE_LINK_MODAL:
            return <LicenseFileLinkModal />
        case ModalTypes.DISPLAY_SIMILAR_NAMES_MODAL:
            return <DisplaySimilarNamesModal />
        default:
            return <></>
    }
})
