import React, { ChangeEvent } from 'react'
import TextField from '@mui/material/TextField'
import { useController, UseControllerProps } from 'react-hook-form'
import {
    useAppDispatch
} from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { productActions } from '../../../../entities/Product'

export const ProductYearOfDevelopment = (props: UseControllerProps) => {
    const { field, fieldState } = useController(props)
    const dispatch = useAppDispatch()

    const handleYearOfDevelopmentChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        field.onChange(e)
        dispatch(productActions.setYearOfDevelopment(e.target.value))
    }

    return (
        <TextField
            {...field}
            required
            fullWidth
            placeholder="YYYY"
            label="Year of development"
            helperText={fieldState.error?.message}
            error={!!fieldState.error?.message}
            onChange={handleYearOfDevelopmentChange}
        />
    )
}
