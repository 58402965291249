import { memo } from 'react'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { RoutePath } from '../../../shared/config/routeConfig/routeConfig'
import { classNames } from '../../../shared/lib/classNames/classNames'
import { Button, ButtonSize } from '../../../shared/ui/Button/Button'
import { useAuth } from '../../../shared/lib/hooks/useAuth/useAuth'
import { SvgIcon } from '../../../shared/ui/SvgIcon/SvgIcon'
import { AppLogo } from '../../../shared/ui/AppLogo/AppLogo'
import { AppLink } from '../../../shared/ui/AppLink/AppLink'
import classes from './Appbar.module.scss'

interface AppbarProps {
	toggleSidebar: () => void
	sidebarOpen: boolean
	className?: string
}

export const Appbar = memo((props: AppbarProps) => {
    const { toggleSidebar, sidebarOpen, className } = props
    const { authUser } = useAuth()

    return (
        <div className={classNames(classes['appbar'], {}, [className])}>
            {authUser && (
                <Button
                    onClick={toggleSidebar}
                    size={ButtonSize.XXL}
                    className={classes['sidebar-toggle']}
                >
                    <SvgIcon
                        icon={faBars}
                    />
                    {sidebarOpen && <span className={classes.menu}>Menu</span>}
                </Button>
            )}
            <AppLink className={classes['main-logo']} to={RoutePath.licenses}>
                <AppLogo />
            </AppLink>
        </div>
    )
})
