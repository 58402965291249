import { IInstance } from '../../../Instances'
import { FunctionalityTemplate } from '../../../Functionality'

export enum LicenseState {
	ACTIVE = 'active',
	ON_HOLD_DEBTOR = 'onHoldDebtor',
	CANCELLED_BUGS = 'cancelledBugs',
	ON_HOLD_BY_REQUEST = 'onHoldByRequest',
	CONTRACT_CANCELLATION = 'contractCancellation',
	CANCELLED_COST_REDUCE = 'cancelledCostReduce',
	CANCELLED_NEW_STRATEGY = 'cancelledNewStrategy',
	CANCELLED_FOR_TECH_REASONS = 'cancelledForTechnicalReasons',
	EXPIRED = 'expired',
}

export enum LicenseType {
	REAL = 'real',
	TRIAL = 'trial',
	_ = '',
}

export interface FunctionalityWithDisplayName {
	functionalityTemplateId: string
	displayName: string,
	value: string
}

export interface OneFunctionalityInSet {
	functionalityId: string,
	value: string
}

export type FunctionalitiesSet = Record<
	OneFunctionalityInSet['functionalityId'],
	OneFunctionalityInSet['value']
>

export interface ILicense {
	licenseId: string
	creationDate: string
	expirationDate?: Date
	type: LicenseType
	state: LicenseState
	maxLiveInstances: number
	isMaxLiveInstancesExceeded: boolean
	allowedVersions: string[]
	numberOfInstances: string
	clientBrief: {
		clientId: string
		companyNames: string[]
	}
	instances?: Array<IInstance>
	productBrief: {
		name: string
		productId: string
		functionalitiesTemplates: Array<FunctionalityTemplate>
	}
	functionalitiesSet?: FunctionalitiesSet
	functionalitiesWithDisplayName?: Array<FunctionalityWithDisplayName>
}

export interface LicenseUpdate {
	licenseId: string
	userId: string
	updateTime: string
	expirationDate: string
	type: LicenseType
	state: string
	maxLiveInstances: number
	allowedVersions: string[]
}

export interface ILicenseWithUpdates extends ILicense {
	licenseUpdates: Array<LicenseUpdate>
}

export interface LicenseSchema {
	cachedLicense: ILicenseWithUpdates
	licenseEditable: ILicenseWithUpdates
	isLoading: boolean
	error?: string
}
