import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { instancesActions } from '../slices/instancesSlice'
import { fetchInstances } from './fetchInstances'

export const fetchInitialInstances = createAsyncThunk<
	void,
	{ licenseId: string, searchParams: URLSearchParams },
	ThunkConfig<void>
>('instances/fetchInitialInstances', async (args, thunkAPI) => {
	const { licenseId, searchParams } = args
	const { dispatch } = thunkAPI

	const sort = searchParams.get('sort')
	const direction = searchParams.get('direction')

	try {
		if (sort) dispatch(instancesActions.setSort(sort))
		if (direction) dispatch(instancesActions.setDirection(direction))
		dispatch(instancesActions.setQueryFilter())

		await dispatch(fetchInstances(licenseId))
	} catch (e) {
		console.log(e)
	}
})