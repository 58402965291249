import { ChangeEvent, ReactNode, useMemo } from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { SxProps } from '@mui/material'

const style = { width: '24rem', margin: '0' }

interface FunctionalityDisplayNameProps {
	index: number
	displayName: string
	functionalityId: string
	register: UseFormRegister<any>
	validationErrors: FieldErrors<any>
	onFunctionalityDisplayNameChange: (displayName: string, index: number) => void
	className?: string
	styles?: SxProps
}

export const FunctionalityDisplayName = (props: FunctionalityDisplayNameProps) => {
    const {
        className,
        displayName = '',
        functionalityId,
        styles,
        index,
        register,
        validationErrors: errors,
        onFunctionalityDisplayNameChange
    } = props

    const sxStyle = useMemo(() => ({ ...style, ...styles }), [styles])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onFunctionalityDisplayNameChange(e.target.value, index)
    }

    return (
        <TextField
            required
            type="text"
            sx={sxStyle}
            variant="outlined"
            className={className}
            label="Functionality display name"
            error={!!errors?.[`displayName${functionalityId}`]?.message}
            helperText={(errors?.[`displayName${functionalityId}`]?.message as ReactNode) || ''}
            {...register(`displayName${functionalityId}`, {
                required: 'Fill out this field, please'
            })}
            onChange={handleChange}
            value={displayName}
        />
    )
}
