import {
    FunctionalitiesTemplatesModified,
    FunctionalityTemplate
} from '../../../../Functionality'
import { v4 as uuidv4 } from 'uuid'

export const mapProductFunctionalities =
	(functionalitiesTemplates: Array<FunctionalityTemplate>): Array<FunctionalitiesTemplatesModified> => {
	    return functionalitiesTemplates.map(
	        (functionality) => {
	            const valuesMapped = functionality.values.map((val) => {
	                return { valueId: uuidv4(), value: val }
	            })
	            return { ...functionality, values: valuesMapped }
	        }
	    )
	}
