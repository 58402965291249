import { createAsyncThunk } from '@reduxjs/toolkit'
import { CommonUser } from '../types/Common'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'

export const fetchCommonUsersAll = createAsyncThunk<
	Array<CommonUser>,
	void,
	ThunkConfig<string>
>('common/fetchCommonUsersAll', async (_, thunkAPI) => {
    const {
        extra: { api },
        rejectWithValue
    } = thunkAPI

    try {
        const res = await api.get<Array<CommonUser>>('/common/users', {
            params: { state: 'all' }
        })
        return res.data
    } catch (e) {
        console.log(e)
        appHandleError(e, 'fetchCommonData', 'Failed to fetch common data')
        return rejectWithValue('Error occurred while fetching all common users')
    }
})
