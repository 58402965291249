import React, { memo } from 'react'
import TextField from '@mui/material/TextField'
import {
    useAppSelector
} from '../../../../shared/lib/hooks/useAppSelector/useAppSelector'
import {
    useAppDispatch
} from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { productActions } from '../../../../entities/Product'

export const ProductDescription = memo(() => {
    const dispatch = useAppDispatch()
    const description = useAppSelector(
        (state) => state.product.editableProduct.description
    )

    const handleDescriptionChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        dispatch(productActions.setDescription(e.target.value))
    }

    return (
        <TextField
            fullWidth
            id="description"
            label="Description"
            name="description"
            multiline
            maxRows={2}
            value={description}
            onChange={handleDescriptionChange}
        />
    )
})
