import { createSlice } from '@reduxjs/toolkit'
import { ProductPublicKeySchema } from '../types/ProductPublicKey'
import { getProductPublicKey } from '../services/getProductPublicKey'

const initialState: ProductPublicKeySchema = {
    productPublicKey: '',
    isLoading: false,
    error: '',
}

const productPublicKeySlice = createSlice({
    name: 'productPublicKey',
    initialState,
    reducers: {
        setProductPublicKey: (state, action) => {
            state.productPublicKey = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductPublicKey.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(getProductPublicKey.fulfilled, (state, action) => {
                state.isLoading = false
                state.productPublicKey = action.payload
            })
            .addCase(getProductPublicKey.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    },
})

export const { actions: productPublicKeyActions } = productPublicKeySlice
export const { reducer: productPublicKeyReducer } = productPublicKeySlice
