import { ChangeEvent, ReactNode, useMemo } from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { SxProps } from '@mui/material'

const style = { width: '24rem', margin: '0' }

interface FunctionalityNameProps {
	index: number
	functionalityId: string
	functionalityName: string
	register: UseFormRegister<any>
	validationErrors: FieldErrors<any>
	onFunctionalityNameChange: (value: string, index: number) => void
	className?: string
	styles?: SxProps
}

export const FunctionalityName = (props: FunctionalityNameProps) => {
    const {
        className,
        functionalityId,
        functionalityName = '',
        styles,
        index,
        register,
        validationErrors: errors,
        onFunctionalityNameChange
    } = props

    const sxStyle = useMemo(() => ({ ...style, ...styles }), [styles])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onFunctionalityNameChange(e.target.value, index)
    }

    return (
        <TextField
            required
            type="text"
            sx={sxStyle}
            variant="outlined"
            className={className}
            label="Functionality name"
            error={!!errors?.[`functionalityName${functionalityId}`]?.message}
            helperText={(errors?.[`functionalityName${functionalityId}`]?.message as ReactNode) || ''}
            {...register(`functionalityName${functionalityId}`, {
                required: 'Fill out this field, please'
            })}
            onChange={handleChange}
            value={functionalityName}
        />
    )
}
