import { Control, SubmitHandler, useForm } from 'react-hook-form'
import React, { ChangeEvent } from 'react'
import Box from '@mui/material/Box'
import { SelectChangeEvent } from '@mui/material/Select'
import { ModalForm } from '../../../shared/ui/ModalForm/ModalForm'
import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { useAppSelector } from '../../../shared/lib/hooks/useAppSelector/useAppSelector'
import {
	ProductNamesAutocomplete
} from '../../../shared/ui/Inputs/ProductNamesAutocomplete/ProductNamesAutocomplete'
import { modalActions } from '../../../entities/Modal'
import { Platform, productActions, ProductTier } from '../../../entities/Product'
import { updateProduct } from '../model/services/updateProduct'
import { FormValues } from '../model/types/ProductUpdateFormValues'
import { ProductComponentsUpdate } from './ProductComponents/ProductComponentsUpdate'
import { ProductPermissions } from './ProductPermissions/ProductPermissions'
import { ProductDescription } from './ProductDescription/ProductDescription'
import { ProductBusinessGoal } from './ProductBusinessGoal/ProductBusinessGoal'
import { ProductsCombinedWith } from './ProductsCombinedWith/ProductsCombinedWith'
import {
	ProductUpdateCheckboxes
} from './ProductUpdateCheckboxes/ProductUpdateCheckboxes'
import {
	ProductYearOfDevelopment
} from './ProductYearOfDevelopment/ProductYearOfDevelopment'
import { MuiSelectValidate } from '../../../shared/ui/MuiSelect/MuiSelectValidate'
import { ProductCloudLink } from './ProductCloudLink/ui/ProductCloudLink'
import { MuiStepper } from '../../../shared/ui/MuiStepper/MuiStepper'
import {
	useSessionStorage
} from '../../../shared/lib/hooks/useSessionStorage/useSessionStorage'

const style = {
	display: 'flex',
	gap: '1rem',
	justifyContent: 'space-between',
	mb: '0.5rem'
}

const steps = ['General', 'MT permissions', 'Components']
export const PRODUCT_UPDATE_MODAL_KEY = 'productUpdSteps'

export const UpdateProductModal = () => {
	const dispatch = useAppDispatch()
	const productId = useAppSelector(
		(state) => state.product.productData.productId
	)
	const product = useAppSelector((state) => state.product.editableProduct)


	const [activeStep, setActiveStep] = useSessionStorage(PRODUCT_UPDATE_MODAL_KEY, 0)

	const {
		reset,
		control,
		handleSubmit,
		formState: { isValid }
	} = useForm<FormValues>({
		mode: 'onChange',
		defaultValues: {
			requiredPermissions: {
				mt4Permissions: product.requiredPermissions.mt4Permissions,
				mt5Permissions: product.requiredPermissions.mt5Permissions
			},
			components: product.components,
			name: product.name,
			tier: product.tier,
			platform: product.platform,
			yearOfDevelopment: product.yearOfDevelopment,
			cloudLink: product.cloudLink
		}
	})

	const handleNameChange = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		dispatch(productActions.setName(e.target.value))
	}

	const handleTireSelect = (e: SelectChangeEvent) => {
		dispatch(productActions.setTier(e.target.value))
	}

	const handlePlatformSelect = (e: SelectChangeEvent) => {
		dispatch(productActions.setPlatform(e.target.value))
	}

	const onSubmit: SubmitHandler<{}> = async (data, event) => {
		event?.preventDefault()
		const result = await dispatch(updateProduct(productId))
		if (result.meta.requestStatus === 'fulfilled') {
			reset()
			setActiveStep(0)
			setTimeout(() => {
				dispatch(modalActions.hideModal())
			})
		}
	}

	const handleCancel = () => {
		dispatch(modalActions.hideModal())
		// dispatch(productActions.resetEditableProduct())
		reset()
	}

	return (
		<ModalForm
			disabled={!isValid}
			handleCancel={handleCancel}
			handleOnSubmit={handleSubmit(onSubmit)}
			modalTitle={'Product update'}
			hideBtns
		>
			<MuiStepper
				steps={steps}
				activeStep={activeStep}
				setActiveStep={setActiveStep}
				stepIsValid={!isValid}
			>
				{activeStep === 0 && (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 3
						}}
					>
						<Box sx={style}>
							<ProductNamesAutocomplete
								name="name"
								control={control as any}
								onProductNameChange={handleNameChange}
								rules={{ required: 'This field is required' }}
							/>
							<MuiSelectValidate
								name={'platform'}
								control={control as any}
								onSelect={handlePlatformSelect}
								rules={{ required: 'This field is required' }}
								labelId={'product-platform'}
								selectOptions={Platform}
								label={'Platform'}
							/>
							<MuiSelectValidate
								name={'tier'}
								control={control as any}
								onSelect={handleTireSelect}
								rules={{ required: 'This field is required' }}
								selectOptions={ProductTier}
								labelId={'tier'}
								label={'Tier'}
							/>
							<ProductYearOfDevelopment
								name="yearOfDevelopment"
								control={control as any}
								rules={{
									required: 'This field is required',
									minLength: {
										value: 4,
										message: 'Not less than 4 characters allowed'
									},
									maxLength: {
										value: 4,
										message: 'Not more than 4 characters allowed'
									},
									pattern: {
										value: /20[1-9]\d/,
										message:
											'Please provide a valid year for T4B product'
									}
								}}
							/>
						</Box>
						<Box sx={style}>
							<ProductCloudLink control={control as unknown as Control} />
							<ProductsCombinedWith />
						</Box>
						<Box sx={style}>
							<ProductDescription />
							<ProductBusinessGoal />
						</Box>
						<ProductUpdateCheckboxes />
					</Box>
				)}
				{activeStep === 1 && <ProductPermissions />}
				{activeStep === 2 && <ProductComponentsUpdate />}
			</MuiStepper>
		</ModalForm>
	)
}
