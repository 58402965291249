// @ts-nocheck
import { ChangeEvent, memo, useEffect, useRef, useState } from 'react'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import { MT4Permissions, MT5Permissions } from '../../../entities/Product'
import ListItem from '@mui/material/ListItem'

const style = {
	'width': '48%',
	'minWidth': 240,
	'bgcolor': '#f4f6f6',
	'border': '1px solid lightgrey',
	'borderRadius': '4px',
	'position': 'relative',
	'overflow': 'auto',
	'maxHeight': 216,
	'& ul': { padding: 0 }
}

const MT4P_MT5P = {
	'MT4 Permissions': MT4Permissions,
	'MT5 Permissions': MT5Permissions
}

interface MTPermissionsProps {
	mtPermissionsType: 'MT4 Permissions' | 'MT5 Permissions'
	mtPermissionsChosen: MT4Permissions[] | MT5Permissions[]
	dispatchMtPermissions: (mtPermissions: string[]) => void
}

// =========== TYPE CHECKING IS DISABLED IN THIS FILE =============

export const MTPermissions = memo((props: MTPermissionsProps) => {
	const {
		mtPermissionsType,
		mtPermissionsChosen,
		dispatchMtPermissions
	} = props

	const [selectAll, setSelectAll] = useState(false)

	const MTPERMISSONS = MT4P_MT5P[mtPermissionsType]
	const mtPermissionsRef = useRef(Object.values(MTPERMISSONS))

	useEffect(() => {
		if (mtPermissionsChosen.length === mtPermissionsRef.current.length && !selectAll) {
			setSelectAll(true)
		}
		if (mtPermissionsChosen.length !== mtPermissionsRef.current.length && selectAll) {
			setSelectAll(false)
		}
	}, [mtPermissionsChosen] /* do not add selectAll */) // eslint-disable-line

	const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {

		setSelectAll(prev => !prev)

		if (e.target.checked) {
			dispatchMtPermissions(mtPermissionsRef.current)
		}
		if (!e.target.checked) {
			dispatchMtPermissions([])
		}
	}

	const handleMTPermissionsCheck = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			if (
				!mtPermissionsChosen.includes(
					MTPERMISSONS[e.target.name]
				)
			) {
				dispatchMtPermissions([
					...mtPermissionsChosen,
					MTPERMISSONS[e.target.name]
				])
			}
			return
		}
		if (!e.target.checked) {
			if (
				mtPermissionsChosen.includes(
					MTPERMISSONS[e.target.name]
				)
			) {
				dispatchMtPermissions(
					mtPermissionsChosen.filter(
						(permission) => permission !== MTPERMISSONS[e.target.name]
					)
				)
			}
		}
	}

	return (
		<List
			sx={style}
			subheader={<li />}
		>
			{[0].map((sectionId) => (
				<li key={`section-${sectionId}`}>
					<ul>
						<ListSubheader sx={{ borderBottom: '1px solid lightgrey' }}>
							{mtPermissionsType}
						</ListSubheader>
						<FormGroup>
							<ListItem
								sx={{ mb: '-1rem' }}
								key={`item-${sectionId}-selectAll`}
							>
								<FormControlLabel
									label="Select all"
									control={
										<Checkbox
											name="selectAll"
											checked={selectAll}
											onChange={handleSelectAll}
										/>
									}
								/>
							</ListItem>
							{(Object.keys(MTPERMISSONS)).map((item) => (
								<ListItem
									key={`item-${sectionId}-${item}`}
									sx={{ py: 0 }}
								>
									<FormControlLabel
										label={item}
										control={
											<Checkbox
												sx={{ ml: '2rem', py: '0.2rem' }}
												name={item}
												checked={mtPermissionsChosen.includes(
													MTPERMISSONS[item]
												)}
												onChange={handleMTPermissionsCheck}
											/>
										}
									/>
								</ListItem>
							))}
						</FormGroup>
					</ul>
				</li>
			))}
		</List>
	)
})
