import { createTheme } from '@mui/material'

const primaryColor = '#4B6687'

const primaryBtnColors = {
    default: '#4B6687',
    hover: '#2C405A',
    active: '#4B668780',
    disabled: '#A7B9D5'
}

const secondaryBtnColors = {
    default: '#8E9094',
    hover: '#6A6B6D',
    active: '#8E909480',
    disabled: '#CFD4D9'
}

const errorBtnColors = {
    default: '#E95050',
    hover: '#C14242',
    active: '#E9505080',
    disabled: '#F48A8A'
}

const infoBtnColors = {
    default: '#40A2B5',
    hover: '#397B8E',
    active: '#40A2B580',
    disabled: '#AEE2F1'
}

export const muiTheme = createTheme({
    palette: {
        primary: {
            main: primaryColor
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    'color': '#FFFFFF',
                    'backgroundColor': primaryBtnColors.default,
                    '&:hover': {
                        backgroundColor: primaryBtnColors.hover
                    },
                    '&:active': {
                        backgroundColor: primaryBtnColors.active
                    },
                    '&:disabled': {
                        backgroundColor: primaryBtnColors.disabled,
                        color: '#FFFFFF'
                    }
                },
                containedSecondary: {
                    'backgroundColor': secondaryBtnColors.default,
                    '&:hover': {
                        backgroundColor: secondaryBtnColors.hover
                    },
                    '&:active': {
                        backgroundColor: secondaryBtnColors.active
                    },
                    '&:disabled': {
                        backgroundColor: secondaryBtnColors.disabled
                    }
                },
                containedError: {
                    'backgroundColor': errorBtnColors.default,
                    '&:hover': {
                        backgroundColor: errorBtnColors.hover
                    },
                    '&:active': {
                        backgroundColor: errorBtnColors.active
                    },
                    '&:disabled': {
                        backgroundColor: errorBtnColors.disabled
                    }
                },
                containedInfo: {
                    'backgroundColor': infoBtnColors.default,
                    '&:hover': {
                        backgroundColor: infoBtnColors.hover
                    },
                    '&:active': {
                        backgroundColor: infoBtnColors.active
                    },
                    '&:disabled': {
                        backgroundColor: infoBtnColors.disabled
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    marginBottom: '0'
                },
                displayedRows: {
                    marginBottom: '0'
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    color: 'white',
                    '&:hover': {
                        color: infoBtnColors.disabled
                    },
                    '&.Mui-active': {
                        '&&': {
                            color: infoBtnColors.disabled,
                            '& * ': {
                                color: infoBtnColors.disabled
                            }
                        }
                    }
                },
                icon: {
                    color: infoBtnColors.disabled
                }
            }
        }
    }
})
