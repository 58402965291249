import { INewProduct, NewProductSchema } from '../types/NewProduct'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addNewProduct } from '../services/addNewProduct'
import { Component } from '../../../../entities/Product'

const newProduct: INewProduct = {
    name: '',
    platform: '',
    description: '',
    tier: '',
    businessGoal: '',
    yearOfDevelopment: '',
    combinedWithProducts: [],
    components: [
        {
            key: '',
            jiraLink: '',
            repositoryLink: '',
            componentName: ''
        }
    ],
    dependencies: [],
    requiredPermissions: {
        mt4Permissions: [],
        mt5Permissions: []
    },
    isApiDataUsed: false,
    isToolboxCompatible: false,
    state: '',
    cloudLink: ''
}

const initialState: NewProductSchema = {
    isLoading: false,
    error: '',
    newProduct
}

const newProductSlice = createSlice({
    name: 'newProductState',
    initialState,
    reducers: {
        resetNewProductState: (state) => {
            state.newProduct = newProduct
        },
        setName: (state, action) => {
            state.newProduct.name = action.payload
        },
        setPlatform: (state, action) => {
            state.newProduct.platform = action.payload
        },
        setProductState: (state, action) => {
            state.newProduct.state = action.payload
        },
        setDescription: (state, action) => {
            state.newProduct.description = action.payload
        },
        setTier: (state, action) => {
            state.newProduct.tier = action.payload
        },
        setBusinessGoal: (state, action) => {
            state.newProduct.businessGoal = action.payload
        },
        setYearOfDevelopment: (state, action) => {
            state.newProduct.yearOfDevelopment = action.payload
        },
        setWebConfiguratorCompatability: (state, action) => {
            state.newProduct.isToolboxCompatible = action.payload
        },
        setCombineWithProducts: (state, action) => {
            state.newProduct.combinedWithProducts = action.payload
        },
        setIsApiDataUsed: (state, action) => {
            state.newProduct.isApiDataUsed = action.payload
        },
        setComponents: (
            state,
            action: PayloadAction<{
				name: keyof Component
				value: string
				index: number
			}>
        ) => {
            const { name, value, index } = action.payload
            state.newProduct.components[index][name] = value
        },
        setNewComponent: (state) => {
            state.newProduct.components.push({
                key: '',
                jiraLink: '',
                repositoryLink: '',
                componentName: ''
            })
        },
        removeComponent: (state, action: PayloadAction<number>) => {
            state.newProduct.components.splice(action.payload, 1)
        },
        setRequiredPermissions: (state, action) => {
            state.newProduct.requiredPermissions = action.payload
        },
        setMT4Permissions: (state, action) => {
            state.newProduct.requiredPermissions.mt4Permissions = action.payload
        },
        setMT5Permissions: (state, action) => {
            state.newProduct.requiredPermissions.mt5Permissions = action.payload
        },
        setDependencies: (state, action) => {
            state.newProduct.dependencies = action.payload
        },
        setCloudLink: (state, action) => {
            state.newProduct.cloudLink = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addNewProduct.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(addNewProduct.fulfilled, (state) => {
                state.isLoading = false
                state.error = ''
            })
            .addCase(addNewProduct.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { actions: newProductActions } = newProductSlice
export const { reducer: newProductReducer } = newProductSlice
