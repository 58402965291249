export enum MT5Permissions {
	RIGHT_ADMIN = 'rightAdmin',
	RIGHT_MANAGER = 'rightManager',
	RIGHT_CFG_SERVERS = 'rightCfgServers',
	RIGHT_CFG_ACCESS = 'rightCfgAccess',
	RIGHT_CFG_TIME = 'rightCfgTime',
	RIGHT_CFG_HOLIDAYS = 'rightCfgHolidays',
	RIGHT_CFG_HST_SYNC = 'rightCfgHstSync',
	RIGHT_CFG_SYMBOLS = 'rightCfgSymbols',
	RIGHT_CFG_GROUPS = 'rightCfgGroups',
	RIGHT_CFG_MANAGERS = 'rightCfgManagers',
	RIGHT_CFG_DATAFEEDS = 'rightCfgDatafeeds',
	RIGHT_CFG_REQUESTS = 'rightCfgRequests',
	RIGHT_SRV_JOURNALS = 'rightSrvJournals',
	RIGHT_SRV_REPORTS = 'rightSrvReports',
	RIGHT_CHARTS = 'rightCharts',
	RIGHT_EMAIL = 'rightEmail',
	RIGHT_ACCOUNTANT = 'rightAccountant',
	RIGHT_ACC_READ = 'rightAccRead',
	RIGHT_ACC_DETAILS = 'rightAccDetails',
	RIGHT_ACC_MANAGER = 'rightAccManager',
	RIGHT_ACC_ONLINE = 'rightAccOnline',
	RIGHT_TRADES_READ = 'rightTradesRead',
	RIGHT_TRADES_MANAGER = 'rightTradesManager',
	RIGHT_QUOTES = 'rightQuotes',
	RIGHT_RISK_MANAGER = 'rightRiskManager',
	RIGHT_REPORTS = 'rightReports',
	RIGHT_NEWS = 'rightNews',
	RIGHT_CFG_GATEWAYS = 'rightCfgGateways',
	RIGHT_CFG_PLUGINS = 'rightCfgPlugins',
	RIGHT_TRADES_DEALER = 'rightTradesDealer',
	RIGHT_CFG_REPORTS = 'rightCfgReports',
	RIGHT_EXPORT = 'rightExport',
	RIGHT_SYMBOL_DETAILS = 'rightSymbolDetails',
	RIGHT_TRADES_SUPERVISOR = 'rightTradesSupervisor',
	RIGHT_QUOTES_RAW = 'rightQuotesRaw',
	RIGHT_MARKET = 'rightMarket',
	RIGHT_GRP_DETAILS_MARGIN = 'rightGrpDetailsMargin',
	RIGHT_NOTIFICATIONS = 'rightNotifications',
	RIGHT_ACC_DELETE = 'rightAccDelete',
	RIGHT_TRADES_DELETE = 'rightTradesDelete',
	RIGHT_CONFIRM_ACTIONS = 'rightConfirmActions',
	RIGHT_CFG_ECN = 'rightCfgEcn',
	RIGHT_GRP_DETAILS_COMMISSION = 'rightGrpDetailsCommission',
	RIGHT_SUBSCRIPTIONS_VIEW = 'rightSubscriptionsView',
	RIGHT_SUBSCRIPTIONS_EDIT = 'rightSubscriptionsEdit',
	RIGHT_CFG_FUNDS = 'rightCfgFunds',
	RIGHT_CFG_MAILS = 'rightCfgMails',
	RIGHT_CFG_MESSENGERS = 'rightCfgMessengers',
	RIGHT_CFG_KYC = 'rightCfgKyc',
	RIGHT_CFG_AUTOMATIONS = 'rightCfgAutomations',
	RIGHT_CFG_ALLOCATIONS = 'rightCfgAllocations',
	RIGHT_CFG_VPS = 'rightCfgVps',
	RIGHT_CFG_PAYMENTS = 'rightCfgPayments',
	RIGHT_ADMIN_COMPUTER = 'rightAdminComputer',
	RIGHT_CFG_WEB_SERVICES = 'rightCfgWebServices',
	RIGHT_FINTEZA_ACCESS = 'rightFintezaAccess',
	RIGHT_FINTEZA_WEBSITES = 'rightFintezaWebsites',
	RIGHT_FINTEZA_CAMPAIGNS = 'rightFintezaCampaigns',
	RIGHT_FINTEZA_REPORTS = 'rightFintezaReports',
	RIGHT_CLIENTS_ACCESS = 'rightClientsAccess',
	RIGHT_CLIENTS_CREATE = 'rightClientsCreate',
	RIGHT_CLIENTS_EDIT = 'rightClientsEdit',
	RIGHT_CLIENTS_DELETE = 'rightClientsDelete',
	RIGHT_DOCUMENTS_ACCESS = 'rightDocumentsAccess',
	RIGHT_DOCUMENTS_CREATE = 'rightDocumentsCreate',
	RIGHT_DOCUMENTS_EDIT = 'rightDocumentsEdit',
	RIGHT_DOCUMENTS_DELETE = 'rightDocumentsDelete',
	RIGHT_DOCUMENTS_FILES_ADD = 'rightDocumentsFilesAdd',
	RIGHT_DOCUMENTS_FILES_DELETE = 'rightDocumentsFilesDelete',
	RIGHT_COMMENTS_ACCESS = 'rightCommentsAccess',
	RIGHT_COMMENTS_CREATE = 'rightCommentsCreate',
	RIGHT_COMMENTS_DELETE = 'rightCommentsDelete',
	RIGHT_CLIENTS_KYC = 'rightClientsKyc',
}
