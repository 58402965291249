import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../shared/lib/appHandleError/appHandleError'
import { fetchComments } from './fetchComments'
import { NewComment } from '../types/Comment'

export const postComment = createAsyncThunk<
	void,
	NewComment,
	ThunkConfig<string>
>(
    'comments/postComment',
    async (newComment, thunkAPI) => {

        const { dispatch, extra: { api }, rejectWithValue } = thunkAPI
        const { objectId, text, type } = newComment

        try {
            await api.post(`/comments/?resourceType=${type}`, { objectId, text })

            dispatch(fetchComments({ objectId, type }))
        } catch (e) {
            console.log(e)
            appHandleError(e, 'postComment', 'Failed to add comment')
            return rejectWithValue('Error occurred on adding a comment')
        }
    })
