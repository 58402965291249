import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ClientSchema, IClient } from '../types/Client'
import { fetchClient } from '../services/fetchClient'

const clientInitValues: IClient = {
	clientId: '',
	tfbOffice: '',
	// @ts-ignore: init client state as en empty string
	state: '',
	numberOfProducts: 0,
	creationDate: '',
	companyNames: [],
	managersBrief: []
}

const initialState: ClientSchema = {
	clientData: clientInitValues,
	cachedClient: clientInitValues,
	managerNames: [],
	isLoading: false,
	error: ''
}

const clientSlice = createSlice({
	name: 'client',
	initialState,
	reducers: {
		setTfbOffice: (state, action) => {
			state.clientData.tfbOffice = action.payload
		},
		addNewCompanyName: (state) => {
			state.clientData.companyNames.push('')
		},
		removeCompanyName: (state, action: PayloadAction<number>) => {
			state.clientData.companyNames.splice(action.payload, 1)
		},
		setCompanyName: (state, action: PayloadAction<{
			companyName: string,
			index: number
		}>) => {
			const { companyName, index } = action.payload
			state.clientData.companyNames[index] = companyName
		},
		setManagersBrief: (state, action) => {
			state.clientData.managersBrief = action.payload
		},
		setManagers: (state, action) => {
			state.managerNames = action.payload
		},
		resetClientData: (state) => {
			state.clientData = state.cachedClient
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchClient.pending, (state) => {
				state.isLoading = true
				state.error = ''
			})
			.addCase(fetchClient.fulfilled, (state, action) => {
				state.isLoading = false
				state.clientData = action.payload.client
				state.cachedClient = action.payload.client
				state.managerNames = action.payload.managerNames
			})
			.addCase(fetchClient.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})
	}
})

export const { actions: clientActions } = clientSlice
export const { reducer: clientReducer } = clientSlice
