import { createAsyncThunk } from '@reduxjs/toolkit'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { addQueryParams } from '../../../../shared/lib/addQueryParams/addQueryParams'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { PaginatedData } from '../../../../shared/types/PaginatedData'
import { IUser } from '../../../../entities/User'
import { selectLimit } from '../selectors/selectLimit'
import { selectState } from '../selectors/selectState'
import { selectCurrentPage } from '../selectors/selectCurrentPage'
import {
	mapRightsNullToNoPermission
} from '../../../../shared/lib/mappers/mapRightsNullToNoPermission'
import { mapCSuite } from '../../../../shared/lib/mappers/mapCSuite'
import { selectFullNameQuery } from '../selectors/selectFullName'
import { selectDepartmentNameQuery } from '../selectors/selectDepartmentName'
import { mapC_Suit } from '../../../../shared/lib/mappers/mapC_Suit'

export const fetchUsers = createAsyncThunk<
	PaginatedData<IUser>,
	void,
	ThunkConfig<string>
>('users/fetchUsers', async (_, thunkAPI) => {

	const { extra: { api }, rejectWithValue, getState } = thunkAPI

	const page = selectCurrentPage(getState())
	const limit = selectLimit(getState())
	const state = selectState(getState())
	const fullName = selectFullNameQuery(getState())
	const department = selectDepartmentNameQuery(getState())
	const departmentName = mapC_Suit(department) // map c-suit -> cSuit

	const params = { fullName, departmentName, page, state, limit }

	addQueryParams(params)
	try {

		const { data } = await api.get<PaginatedData<IUser>>(
			'/users',
			{ params }
		)

		const users: Array<IUser> = data.items.map((user) => {
			mapCSuite(user)
			return mapRightsNullToNoPermission(user)
		})
		data.items = users

		return data
	} catch (e) {
		console.log(e)
		appHandleError(e, 'fetchUsers', 'Failed to fetch users data')
		return rejectWithValue('Error occurred on fetching users list')
	}
})
