import React from 'react'
import {
    useAppDispatch
} from '../../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import {
    useAppSelector
} from '../../../../../shared/lib/hooks/useAppSelector/useAppSelector'
import { MTPermissions } from '../../../../../entities/MTPermissions/ui/MTPermissions'
import { productActions } from '../../../../../entities/Product'

export const ProductUpdateMT5Permissions = () => {
    const dispatch = useAppDispatch()

    const mt5PermissionsChosen = useAppSelector(
        (state) =>
            state.product.editableProduct.requiredPermissions.mt5Permissions
    )

    const dispatchOnMtPermissionsCheck = (mtPermissions: string[]) => {
        dispatch(productActions.setMT5Permissions(mtPermissions))
    }

    return (
        <MTPermissions
            mtPermissionsType="MT5 Permissions"
            mtPermissionsChosen={mt5PermissionsChosen}
            dispatchMtPermissions={dispatchOnMtPermissionsCheck}
        />
    )
}
