import { memo, PropsWithChildren } from 'react'
import { classNames } from '../../lib/classNames/classNames'
import classes from './CustomChip.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons/faTrashCan'

interface CustomChipProps extends PropsWithChildren {
	onClick: () => void
	iconX?: boolean
	className?: string;
}

export const CustomChip = memo((props: CustomChipProps) => {
    const { onClick, iconX, className, children } = props

    return (
        <button
            className={classNames(
                classes['custom-chip'],
                {},
                [className]
            )}
            onClick={onClick}
        >
            {children}
            <span
                className={classes['delete-btn']}
            >
                <FontAwesomeIcon icon={iconX ? faXmark : faTrashCan} />
            </span>
        </button>
    )
})
