import React, { memo, useMemo } from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { SxProps } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import { camelToUpper } from '../../lib/caseConverters/camelToUpper'

const style = { width: '24rem', margin: '0' }

type SelectOption = string;
export type SelectOptions = SelectOption[] | Record<string, SelectOption>;

interface SelectProps {
	handleSelectChange: (e: SelectChangeEvent) => void;
	selectOptions: SelectOptions;
	value: string | undefined;
	label: string;
	labelId: string;
	name?: string;
	size?: 'small' | 'medium'
	disabled?: boolean;
	styles?: SxProps;
}

export const MuiSelect = memo((props: SelectProps) => {
    const {
        handleSelectChange,
        selectOptions,
        label,
        labelId,
        name = '',
        value = '',
        size = 'small',
        disabled = false,
        styles = {}
    } = props

    const sxStyle = useMemo(() => ({ ...style, ...styles }), [styles])

    const options = useMemo(() => {
        return Array.isArray(selectOptions)
            ? selectOptions
            : Object.keys(selectOptions) as (keyof typeof selectOptions)[]
    }, [selectOptions])

    const optionItems = useMemo(() => {
        return options.map((option) => {
            const value = Array.isArray(selectOptions) ? option : selectOptions[option]
            return <MenuItem
                key={option}
                value={value}
            >
                {camelToUpper(value)}
            </MenuItem>
        })
    }, [options, selectOptions])

    return (
        <Box sx={sxStyle}>
            <FormControl size={size} fullWidth>
                <InputLabel id={labelId}>
                    {label}
                </InputLabel>
                <Select
                    size={size}
                    name={name}
                    label={label}
                    value={value}
                    labelId={labelId}
                    disabled={disabled}
                    onChange={handleSelectChange}
                >
                    {options && optionItems}
                </Select>
            </FormControl>
        </Box>
    )
})
