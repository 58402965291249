import { SimilarNames, SimilarNamesObjMapped } from '../../types/SimilarNames'

export const mapSimilarNames = (similarNames: SimilarNames) => {
    const similarNamesMapped: Array<SimilarNamesObjMapped> = [] // [{enteredClient, similarClients}]
    const similarNamesEntries = Object.entries(similarNames)

    for (const [enteredClientName, clients] of similarNamesEntries) {
        for (const client of clients) {
            const creationDate = client?.creationDate?.split('T')?.[0]
            const clientCompanyNames = client?.['companyNames']?.join('; ')
            similarNamesMapped.push({
                enteredClient: enteredClientName,
                similarClients: clientCompanyNames,
                creationDate
            })
        }
    }

    return similarNamesMapped
}
