import { createSlice } from '@reduxjs/toolkit'
import { CommonSchema } from '../types/Common'
import { fetchCommonUsers } from '../services/fetchCommonUsers'
import { fetchCommonClients } from '../services/fetchCommonClients'
import { fetchCommonProducts } from '../services/fetchCommonProducts'
import { fetchCommonUsersAll } from '../services/fetchCommonUsersAll'

const initialState: CommonSchema = {
    isLoading: false,
    error: '',
    common: {
        usersAll: [],
        users: [],
        clients: [],
        products: []
    }
}

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCommonUsersAll.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(fetchCommonUsersAll.fulfilled, (state, action) => {
                state.isLoading = false
                state.common.usersAll = action.payload
            })
            .addCase(fetchCommonUsersAll.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
            .addCase(fetchCommonUsers.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(fetchCommonUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.common.users = action.payload
            })
            .addCase(fetchCommonUsers.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
            .addCase(fetchCommonClients.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(fetchCommonClients.fulfilled, (state, action) => {
                state.isLoading = false
                state.common.clients = action.payload
            })
            .addCase(fetchCommonClients.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
            .addCase(fetchCommonProducts.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(fetchCommonProducts.fulfilled, (state, action) => {
                state.isLoading = false
                state.common.products = action.payload
            })
            .addCase(fetchCommonProducts.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})

export const { actions: commonActions } = commonSlice
export const { reducer: commonReducer } = commonSlice
