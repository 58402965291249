import { Suspense, useCallback, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { useAppDispatch } from '../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { classNames } from '../shared/lib/classNames/classNames'
import { useAuth } from '../shared/lib/hooks/useAuth/useAuth'
import { Loader } from '../shared/ui/Loader/Loader'
import {
    fetchCommonClients,
    fetchCommonProducts,
    fetchCommonUsers,
    fetchCommonUsersAll
} from '../entities/Common'
import { Sidebar } from '../widgets/Sidebar'
import { Appbar } from '../widgets/Appbar'
import { Modals } from './providers/Modals'
import { AppRouter } from './providers/Router'
import './styles/index.scss'

export const App = () => {
    const dispatch = useAppDispatch()
    const { authUser } = useAuth()
    const [sidebarOpen, setSidebarOpen] = useState(false)

    useEffect(() => {
        if (authUser) {
            dispatch(fetchCommonUsers())
            dispatch(fetchCommonClients())
            dispatch(fetchCommonProducts())
            dispatch(fetchCommonUsersAll())
        }
    }, [dispatch, authUser])

    const onSidebarToggle = useCallback(() => {
        setSidebarOpen((prev) => !prev)
    }, [])

    return (
        <div className={classNames('app-main', {}, [])}>
            <Appbar toggleSidebar={onSidebarToggle} sidebarOpen={sidebarOpen} />
            <div className="content-page">
                <Suspense fallback={<Loader />}>
                    {authUser && <Sidebar sidebarOpen={sidebarOpen} />}
                    <AppRouter />
                    <Modals />
                </Suspense>
            </div>
            <ToastContainer limit={3} />
        </div>
    )
}
