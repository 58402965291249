import { memo } from 'react'
import Button from '@mui/material/Button'

interface FilterControlsProps {
	handleApply: () => void
}

export const FilterApply = memo(({ handleApply }: FilterControlsProps) => (
    <Button
        color="info"
        variant="contained"
        onClick={handleApply}
        sx={{ px: '2rem' }}
    >
		Apply
    </Button>
))
