import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { IUser } from '../types/User'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import {
    mapRightsNullToNoPermission
} from '../../../../shared/lib/mappers/mapRightsNullToNoPermission'
import { mapCSuite } from '../../../../shared/lib/mappers/mapCSuite'

export const fetchUser = createAsyncThunk<IUser, string, ThunkConfig<string>>(
    'user/fetchUser',
    async (userId, thunkApi) => {
        const {
            extra: { api },
            rejectWithValue
        } = thunkApi

        try {
            const { data } = await api.get<IUser>(`/users/${userId}`)
            const userWithMappedRights = mapRightsNullToNoPermission(data)
            mapCSuite(userWithMappedRights)

            return userWithMappedRights
        } catch (e) {
            console.log(e)
            appHandleError(e, 'fetchUser', 'Failed to fetch user data')
            return rejectWithValue('Error occurred on fetching user data')
        }
    }
)
