import { memo, useCallback } from 'react'
import Box from '@mui/material/Box'
import { selectQueryFilter } from '../../model/selectors/selectQueryFilter'
import { instancesActions } from '../../model/slices/instancesSlice'
import { fetchInstances } from '../../model/srevices/fetchInstances'
import { useParams } from 'react-router-dom'
import { ChipResetFilter } from '../../../../shared/ui/ChipResetFilter/ChipResetFilter'
import { InstancesFilterChip } from './InstancesFilterChip'
import {
	useAppDispatch
} from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import {
	useAppSelector
} from '../../../../shared/lib/hooks/useAppSelector/useAppSelector'

interface InstancesFilterChipListProps {
	licenseId: string
}

export const InstancesFilterChipList = memo((props: InstancesFilterChipListProps) => {
	const { licenseId } = props
	const dispatch = useAppDispatch()
	const { licenseId: licIdFromParams } = useParams()

	const filters = useAppSelector(selectQueryFilter)
	const activeFilters = Object.entries(filters).filter(([_, filterValue]) => filterValue)
	const activeFiltersLen = activeFilters.length

	const handleReset = useCallback(() => {
		dispatch(instancesActions.resetFilter())
		dispatch(fetchInstances(licIdFromParams ?? licenseId))
	}, [dispatch, licIdFromParams, licenseId])

	return (
		<>
			{!!activeFiltersLen && (
				<Box sx={{
					marginBottom: '1.5rem',
					display: 'flex',
					gap: 1,
					flexWrap: 'wrap'
				}}>
					<ChipResetFilter handleReset={handleReset} />
					{activeFilters.map(([filterKey, filterValue]) => (
						<InstancesFilterChip
							key={filterKey + filterValue}
							filterKey={filterKey}
							filterValue={filterValue as string}
							licenseId={licenseId}
						/>
					))}
				</Box>
			)}
		</>
	)
})
