import { createAsyncThunk } from '@reduxjs/toolkit'
import { CommonUser } from '../types/Common'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'

export const fetchCommonUsers = createAsyncThunk<
    Array<CommonUser>,
    void,
    ThunkConfig<string>
>('common/fetchCommonUsers', async (_, thunkAPI) => {
    const {
        extra: { api },
        rejectWithValue,
    } = thunkAPI

    try {
        const res = await api.get<Array<CommonUser>>('/common/users')
        return res.data
    } catch (e) {
        console.log(e)
        appHandleError(e, 'fetchCommonData', 'Failed to fetch common data')
        return rejectWithValue('Error occurred while fetching common users')
    }
})
