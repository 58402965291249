import { PaginatedData } from '../../../../shared/types/PaginatedData'
import { ILicense, LicenseState, LicenseType } from '../../../../entities/License'
import { Direction } from '../../../../shared/types/Direction'

export enum MaxLiveInstancesExceeded {
	EXCEEDED = 'exceeded',
	NOT_EXCEEDED = 'notExceeded',
}

export interface LicensesSchema {
	paginatedLicenses: PaginatedData<ILicense>
	limit: number

	// license type filter
	type?: LicenseType

	// filter properties
	queryFilter: {
		companyName?: string
		productName?: string
		managerName?: string
		state?: LicenseState
		creationDateFrom?: string
		creationDateTo?: string
		expirationDateFrom?: string
		expirationDateTo?: string
		serverIp?: string
		maxLiveInstancesExceeded?: MaxLiveInstancesExceeded
	}
	filter: {
		companyName?: string
		productName?: string
		managerName?: string
		state?: LicenseState
		creationDateFrom?: string
		creationDateTo?: string
		expirationDateFrom?: string
		expirationDateTo?: string
		serverIp?: string
		maxLiveInstancesExceeded?: MaxLiveInstancesExceeded
	}

	// sort properties
	sort: {
		direction?: Direction
		sort?: string
	}

	isLoading?: boolean
	error?: string
}
