import { ButtonHTMLAttributes, memo } from 'react'
import { classNames, Mods } from '../../lib/classNames/classNames'
import classes from './Button.module.scss'

export enum ButtonSize {
    XXS = 'btn-xxs',
    XS = 'btn-xs',
    S = 'btn-s',
    M = 'btn-m',
    L = 'btn-l',
    XL = 'btn-xl',
    XXL = 'btn-xxl',
}

export enum ButtonTheme {
    APPLY = 'btn-apply',
    DELETE = 'btn-delete',
    CANCEL = 'btn-cancel',
    RESET = 'btn-reset',
    ADD = 'btn-add',
    CLEAR = 'btn-clear',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
    theme?: ButtonTheme
    size?: ButtonSize
    disabled?: boolean
}

export const Button = memo((props: ButtonProps) => {
    const {
        type = 'button',
        children,
        className,
        disabled,
        theme = ButtonTheme.CLEAR,
        size = '',
        ...restProps
    } = props

    const mods: Mods = {
        [classes.disabled]: disabled,
        [classes[size]]: true,
    }

    return (
        <button
            type={type}
            className={classNames(classes.button, mods, [
                className,
                classes[theme],
            ])}
            {...restProps}
        >
            {children}
        </button>
    )
})
