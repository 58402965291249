import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import { fetchCommonUsers, fetchCommonUsersAll } from '../../../../entities/Common'
import { fetchUsers } from '../../../../pages/UsersPage'
import { selectNewUser } from '../selectors/selectNewUser'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { mapC_Suit } from '../../../../shared/lib/mappers/mapC_Suit'

export const addNewUser = createAsyncThunk<string, void, ThunkConfig<string>>(
    'newUserState/addNewUser',
    async (_, thunkAPI) => {

        const { dispatch, extra: { api }, rejectWithValue, getState } = thunkAPI

        const newUser = selectNewUser(getState())
        const fullName = newUser.fullName?.trim()
        const departmentName = mapC_Suit(newUser.departmentName)
        const newUserDTO = { ...newUser, fullName, departmentName }
        try {
            const { data: { objectId } } = await api.post('/users', newUserDTO)

            showToast({
                type: 'success',
                toastId: 'newUserCreated',
                message: 'New user created'
            })

            dispatch(fetchUsers())
            dispatch(fetchCommonUsers())
            dispatch(fetchCommonUsersAll())

            return objectId?.length ? objectId : ''
        } catch (e) {
            console.log(e)
            appHandleError(e, 'addNewUser', 'Failed to add a new user')
            return rejectWithValue('Error occurred while adding a new user')
        }
    }
)
