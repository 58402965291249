import React, { memo } from 'react'
import TextField from '@mui/material/TextField'
import {
    useAppSelector
} from '../../../../shared/lib/hooks/useAppSelector/useAppSelector'
import {
    useAppDispatch
} from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { productActions } from '../../../../entities/Product'

export const ProductBusinessGoal = memo(() => {
    const dispatch = useAppDispatch()
    const businessGoal = useAppSelector(
        (state) => state.product.editableProduct.businessGoal
    )

    const handleBusinessGoalChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        dispatch(productActions.setBusinessGoal(e.target.value))
    }

    return (
        <TextField
            fullWidth
            id="business-goal"
            label="Business Goal"
            name="businessGoal"
            multiline
            maxRows={2}
            value={businessGoal}
            onChange={handleBusinessGoalChange}
        />
    )
})
