import { createSlice } from '@reduxjs/toolkit'
import { InstancesSchema } from '../types/Instance'
import { fetchInstances } from '../srevices/fetchInstances'


const initialState: InstancesSchema = {
	isLoading: false,
	error: '',

	instancesData: [],
	filter: {},
	queryFilter: {},
	sort: {}
}

const instancesSlice = createSlice({
	name: 'instances',
	initialState,
	reducers: {
		setSort: (state, action) => {
			state.sort.sort = action.payload
		},
		setDirection: (state, action) => {
			state.sort.direction = action.payload
		},
		setInstanceName: (state, action) => {
			state.filter.instanceName = action.payload
		},
		setLabel: (state, action) => {
			state.filter.label = action.payload
		},
		setIp: (state, action) => {
			state.filter.ip = action.payload
		},
		setProductVersion: (state, action) => {
			state.filter.productVersion = action.payload
		},
		setQueryFilter: (state) => {
			state.queryFilter = { ...state.filter }
		},
		removeFilter: (state, action) => {
			const filterName: keyof typeof state.queryFilter = action.payload
			delete state.filter[filterName]
			delete state.queryFilter[filterName]
		},
		resetFilter: (state) => {
			state.filter = {}
			state.queryFilter = {}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchInstances.pending, (state) => {
				state.isLoading = true
				state.error = ''
			})
			.addCase(fetchInstances.fulfilled, (state, action) => {
				state.isLoading = false
				state.instancesData = action.payload
			})
			.addCase(fetchInstances.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})
	}
})

export const { actions: instancesActions } = instancesSlice
export const { reducer: instancesReducer } = instancesSlice
